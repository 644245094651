import { call, put, takeLatest, fork, takeEvery } from 'redux-saga/effects';
import externalServicesApi from 'services/api/ExternalServicesApi';
import handleError from 'services/error/handleScopeError';
import { communicationStatus, HttpStatus } from 'services/utils';
import { loggerActions } from 'store/logger';
import loggerConstants from 'store/logger/constant';
import sharedSagas from 'store/sharedSagas';

import actions from './actions';
import types from './types';

const { BAD_REQUEST } = HttpStatus;
const { LOADING, SUCCEEDED, FAILED } = communicationStatus;

function* errorHandler(err) {
  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

function* handleGetList() {
  try {
    yield put(actions.setStatusList(LOADING));
    const { data } = yield call(externalServicesApi.getList);
    yield put(actions.setDataList(data));
  } catch (e) {
    yield fork(errorHandler, e);
    yield put(actions.setStatusList(FAILED));
  }
}

function* handleActive(action) {
  const { data, callback } = action.payload;
  try {
    yield put(actions.setStatusDetail(LOADING));
    yield call(externalServicesApi.active, data);
    yield put(actions.setStatusDetail(SUCCEEDED));
    callback();
    yield fork(handleGetList);
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];
    const newErrors = errors.map((error) =>
      ['INVALID_S3_PERMISSION', 'INVALID_GCS_PERMISSION'].includes(error.code)
        ? { ...error, field: 'bucket_name' }
        : { ...error }
    );

    yield put(actions.setErrorDetail(newErrors, scope));
    callback(true, newErrors);
  }
}

function* handleDeactive(action) {
  const { key, callback } = action.payload;
  try {
    yield put(actions.setStatusDetail(LOADING));
    yield call(externalServicesApi.deactive, key);
    yield put(actions.setStatusDetail(SUCCEEDED));
    callback();
    yield fork(handleGetList);
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [BAD_REQUEST]);
    const errors = error?.data?.errors || [];

    yield put(actions.setErrorDetail(errors, scope));
    callback(true, errors);
  }
}

export default function* watch() {
  yield takeLatest(
    types.GET_LIST,
    sharedSagas.safe(errorHandler, handleGetList)
  );

  yield takeEvery(types.ACTIVE, sharedSagas.safe(errorHandler, handleActive));
  yield takeLatest(
    types.DEACTIVE,
    sharedSagas.safe(errorHandler, handleDeactive)
  );
}
