import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { isEmpty, isArray, chain, invert, get } from 'lodash';
import moment from 'moment';
import DragonBall from 'views/atoms/dragonball/DragonBall';
import Spinner from 'views/atoms/loader/Spinner';
import {
  IconCautionYellow,
  IconMultiplePerson,
} from 'views/atoms/icon/SvgIcon';
import * as FILTERS from 'services/consts';
import { getErrorMessageByCode, isStringEmpty } from 'services/utils';
import { DATA_EXPORT } from 'services/routes/constants';
import { ERROR_MESSAGE_BY_FIELD } from 'services/validations/dataExportErrorMessages';
import {
  dataExportRules,
  metricRules,
  periodRules,
  periodIntervalRules,
  emailTitleModeRules,
  emailTitleRules,
  googleAccountRules,
  regularReportRules,
  commonRules,
} from 'services/validations/dataExportRules';
import {
  acceptsValue,
  isBetweenPeriod,
  maxLine,
} from 'services/validations/commonValidations';
import { resolve } from 'domain/permissions/permissionTypes';
import FilterService from 'domain/FilterService';
import ViewService from 'domain/ViewService';
import DisplayItemsService from 'domain/settings/DisplayItemsService';
import * as DisplayItems from 'domain/settings/display-items';
import {
  CALENDAR_DAY_FORMAT,
  UNREGISTERED_ID_FILTER_KEY,
  UNREGISTERED_DISPLAY_VALUE,
  API_UNLIMITED_VALUE,
  CHANNEL_TYPE_ID,
} from 'domain/consts';
import {
  INVALID_CREDENTIALS,
  OVER_MILLION_CELLS,
  STATUS_TYPE_WAITING,
  STATUS_TYPE_RUNNING,
  STATUS_TYPE_COMPLETED,
  STATUS_REPORT_ERROR,
  STATUS_REPORT_PROCESSING,
  STATUS_REPORT_SUCCESS,
  STATUS_REPORT_ERROR_INVALID_CREDENTIALS,
  STATUS_REPORT_ERROR_NO_PERMISSION,
  RESULT_TYPE_SUCCESS,
  SERVICE_STORE_REPORT_GOOGLE_DRIVE,
  SERVICE_STORE_REPORT_S3,
  REPORT_TYPES,
  STATUS_REPORT_ERROR_OVER_MILLION_CELLS,
  STATUS_PERIOD_MODE_NORMAL,
  STATUS_PERIOD_MODE_PERIODIC,
  STATUS_PERIOD_INTERVAL_DAY,
  STATUS_PERIOD_INTERVAL_WEEK,
  PERIOD_INTERVAL_DAY,
  PERIOD_INTERVAL_WEEK,
  PERIOD_INTERVAL_MONTH,
  WEEK_DAYS,
  STATUS_REPORT_EXPIRED,
  STATUS_REPORT_EMAIL_FAIL,
  ERRORS_SHEET,
  NO_PERMISSION,
  PERIOD_MONDAY_KEY,
  PERIOD_SUNDAY_KEY,
  MSG_NO_PERMISSION_SETTING,
  NO_EXIST_EMAIL,
  DISPLAYITEM_GROUP_CHANNEL,
  DISPLAYITEM_GROUP_TIME,
  DISPLAYITEM_GROUP_CONVERSION,
  DISPLAYITEM_GROUP_SITE,
  DISPLAYITEM_GROUP_CV_ATTRIBUTE,
  DISPLAYITEM_FIXED,
  REPORT_TYPE_CHANNEL,
  REPORT_TYPE_ACCESS,
  REPORT_TYPE_CV_ATTRIBUTE,
  REPORT_TYPE_AD_REPO,
  SERVICE_STORES,
  REPORT_FIELDS,
  EMAIL_TITLE_MODE_DEFAULT,
  WEEKDAY_MONDAY,
  WAIT_COST_ON,
  WAIT_COST_OFF,
  EMAIL_TITLE_MODE_CUSTOM,
  MAX_DIMENSION,
  FILTER_KEY_MAPPER,
  FILTER_OPERATOR_MAPPER,
  FILTER_CHANNEL_MAPPER,
  FILTER_INDIRECT_MAPPER,
  FILTER_CONTACT_HISTORY_MAPPER,
  SERVICE_STORE_GOOGLE_DRIVE,
  CONTACT_HISTORIES,
  DISPLAYITEM_KEY_MAPPER,
  GOOGLE_DRIVE_DIR_PATH,
  REPORT_TYPES_REGULAR_REPORT,
  REPORT_TYPES_DATA_EXPORT,
  PAGE_ID_BY_REPORT_TYPE,
  REPORT_TYPES_FOR_SETTING,
  MESSAGE_DISABLED_SYSTEM_SYC,
  CHANNEL_TYPE_AD,
  CHANNEL_TYPE_ALL,
  REPORT_TYPE_CATEGORY,
  REPORT_TYPE_DETAIL,
  REPORT_TYPE_PERIOD,
  REPORT_TYPE_CONVERSION,
  REPORT_TYPE_CATEGORY_PERIOD,
  REPORT_TYPE_DETAIL_PERIOD,
  TIME_AXIS_DAILY,
  CROSS_PAID,
} from 'domain/data-export/consts';
import {
  ROLE_VIEW_AD,
  ROLE_VIEW_ALL,
  ROLE_VIEW_ALL_AD_ONLY,
} from 'domain/permissions/roles';
import { getSortString, getSortObject } from 'domain/utils';
import { MEDIA_SYNC_VIEW } from 'domain/permissions/contractPermissions';
import { CHANNEL_VIEW_PERMISSIONS } from 'domain/permissions/contractGroups';
import { MEDIA_SYNC } from 'domain/fields';

const {
  EMAIL,
  SEND_EMAIL_MODE,
  EMAIL_TITLE_MODE,
  EMAIL_TITLE,
  PERIOD,
  PERIOD_MODE,
  PERIOD_INTERVAL,
  PERIOD_RECEIVE_REPORT_DAY,
  WAIT_COST,
  DIMENSIONS,
  METRICS,
  PERIOD_START,
  PERIOD_END,
  CONTACT_HISTORY,
  DISPLAY,
  DISPLAY_TOTAL,
  DISPLAY_ROW_ZERO,
  DISPLAY_HEADER,
  REPORT_TYPE,
  QUICK_SEARCH,
  GOOGLE_ACCOUNT,
  SERVICE_STORE,
  GOOGLE_DIR_PATH,
  GOOGLE_SAVE_MODE,
  REPORT_CHANNEL,
  SORT,
  DISPLAY_ITEMS,
  SUMMARY_MODE,
} = REPORT_FIELDS;
const { allFilterableList } = FILTERS;

export const checkIsRegularReport = (reportType) => {
  return REPORT_TYPES_REGULAR_REPORT.includes(reportType);
};

export const checkIsDataExport = (reportType) => {
  return REPORT_TYPES_DATA_EXPORT.includes(reportType);
};

export const checkIsReportTypePeriod = (reportType) => {
  return [
    REPORT_TYPE_PERIOD,
    REPORT_TYPE_CATEGORY_PERIOD,
    REPORT_TYPE_DETAIL_PERIOD,
  ].includes(reportType);
};

export const getPageIdByReportType = (reportType) => {
  return PAGE_ID_BY_REPORT_TYPE[reportType] || DATA_EXPORT;
};

export const getFuncIdByReportType = (reportType) => {
  const pageId = getPageIdByReportType(reportType);
  return DisplayItemsService.getFuncIdByPageId(pageId);
};

export const getErrorSheet = (resultErrorCode) => {
  switch (resultErrorCode) {
    case INVALID_CREDENTIALS:
      return STATUS_REPORT_ERROR_INVALID_CREDENTIALS;
    case OVER_MILLION_CELLS:
      return STATUS_REPORT_ERROR_OVER_MILLION_CELLS;
    case NO_PERMISSION:
      return STATUS_REPORT_ERROR_NO_PERMISSION;
    default:
      return STATUS_REPORT_ERROR;
  }
};

const isSendMailFail = (emailInfo = []) => {
  if (emailInfo === NO_EXIST_EMAIL) {
    return false;
  }
  return emailInfo.some((email) => email.sentStatus === 0);
};

export const getStatusReport = ({
  statusType,
  resultType,
  resultErrorCode,
  emailInfo = [],
  serviceStore,
  isCsvExpried,
  id,
  paidContract,
  crossDevice,
}) => {
  if (paidContract !== CROSS_PAID && crossDevice) {
    return (
      <>
        <DragonBall
          svg={<IconCautionYellow />}
          placement="top"
          variant="warning"
          stayOpen
        >
          <>
            設定に不備があります。
            <Link to={`/${DATA_EXPORT}/setting/${id}`} className="logo">
              編集画面
            </Link>
            でご確 <br />
            認ください。
          </>
        </DragonBall>
        {STATUS_REPORT_ERROR}
      </>
    );
  }
  if (
    statusType === STATUS_TYPE_WAITING ||
    statusType === STATUS_TYPE_RUNNING
  ) {
    return (
      <div className="grid-table__cell-loading">
        {STATUS_REPORT_PROCESSING} <Spinner />
      </div>
    );
  }
  if (
    statusType === STATUS_TYPE_COMPLETED &&
    resultType !== RESULT_TYPE_SUCCESS
  ) {
    // Handle Error Status for Google speadsheet
    if (
      statusType !== REPORT_TYPE_AD_REPO &&
      serviceStore === SERVICE_STORE_REPORT_GOOGLE_DRIVE
    ) {
      return (
        <div>
          {getErrorSheet(resultErrorCode)}
          {[INVALID_CREDENTIALS, NO_PERMISSION, OVER_MILLION_CELLS].includes(
            resultErrorCode
          ) && <DragonBall>{ERRORS_SHEET[resultErrorCode]}</DragonBall>}
        </div>
      );
    }
    return <>{STATUS_REPORT_ERROR}</>;
  }
  if (
    serviceStore === SERVICE_STORE_REPORT_S3 &&
    statusType === STATUS_TYPE_COMPLETED &&
    resultType === RESULT_TYPE_SUCCESS &&
    isCsvExpried
  ) {
    return STATUS_REPORT_EXPIRED;
  }
  if (isSendMailFail(emailInfo)) {
    return STATUS_REPORT_EMAIL_FAIL;
  }

  return STATUS_REPORT_SUCCESS;
};

export const getStatusReportHistory = ({
  resultType,
  resultErrorCode,
  sendEmailMode,
  serviceStore,
  emailInfo,
}) => {
  if (resultType !== RESULT_TYPE_SUCCESS) {
    if (serviceStore === SERVICE_STORE_REPORT_GOOGLE_DRIVE) {
      return (
        <div>
          {getErrorSheet(resultErrorCode)}
          {[INVALID_CREDENTIALS, NO_PERMISSION, OVER_MILLION_CELLS].includes(
            resultErrorCode
          ) && <DragonBall>{ERRORS_SHEET[resultErrorCode]}</DragonBall>}
        </div>
      );
    }
    return STATUS_REPORT_ERROR;
  }
  if (sendEmailMode) {
    if (!isSendMailFail(emailInfo)) {
      return STATUS_REPORT_SUCCESS;
    }
    if (isEmpty(emailInfo)) {
      return STATUS_REPORT_PROCESSING;
    }
    return STATUS_REPORT_EMAIL_FAIL;
  }
  return STATUS_REPORT_SUCCESS;
};

export const formatPeriodTime = ({
  periodStart,
  periodEnd,
  periodMode,
  periodInterval,
  periodReceiveReport,
  type,
}) => {
  if (
    periodMode !== STATUS_PERIOD_MODE_NORMAL &&
    type !== REPORT_TYPE_AD_REPO
  ) {
    if (periodInterval === STATUS_PERIOD_INTERVAL_DAY) {
      return PERIOD_INTERVAL_DAY;
    }
    if (periodInterval === STATUS_PERIOD_INTERVAL_WEEK) {
      const periodReceive = !periodReceiveReport
        ? PERIOD_MONDAY_KEY
        : periodReceiveReport;
      const startDay = WEEK_DAYS[periodReceive];
      const endDayIndex =
        periodReceive - 1 >= PERIOD_MONDAY_KEY
          ? periodReceive - 1
          : PERIOD_SUNDAY_KEY;
      const endDay = WEEK_DAYS[endDayIndex];
      return (
        <>
          <span>{PERIOD_INTERVAL_WEEK}</span>
          <span className="d-inline-block">
            （{startDay}-{endDay}曜日）
          </span>
        </>
      );
    }
    return PERIOD_INTERVAL_MONTH;
  }

  return (
    <>
      <span>{periodStart}-</span>
      <span className="d-inline-block">{periodEnd}</span>
    </>
  );
};

const getOutputReport = ({
  resultType,
  statusType,
  serviceStore,
  isCsvExpried,
  spreadsheetLink,
  onClickDownload,
  type,
  id,
  paidContract,
  crossDevice,
}) => {
  if (type === REPORT_TYPE_AD_REPO) {
    return (
      <Button variant="text" size="sm">
        アドレポ
      </Button>
    );
  }
  const isStatusSucceeded =
    resultType === RESULT_TYPE_SUCCESS && statusType === STATUS_TYPE_COMPLETED;
  const isAllowDownloadCsv =
    serviceStore === SERVICE_STORE_REPORT_S3 &&
    isStatusSucceeded &&
    !isCsvExpried;
  const isAllowDownloadSheet =
    serviceStore === SERVICE_STORE_REPORT_GOOGLE_DRIVE &&
    isStatusSucceeded &&
    spreadsheetLink;
  if (paidContract !== CROSS_PAID && crossDevice) {
    return (
      <Button variant="text" size="sm">
        CSV
      </Button>
    );
  }
  if (isAllowDownloadCsv || isAllowDownloadSheet) {
    return (
      <Button
        variant="secondary"
        size="xs"
        className="btn-icon--with-text btn-no-focus"
        onClick={(e) => {
          e.stopPropagation();
          onClickDownload(id, spreadsheetLink);
        }}
      >
        <i className="far fa-arrow-to-bottom" />
        {isAllowDownloadCsv ? 'CSVダウンロード' : 'スプレッドシート'}
      </Button>
    );
  }
  return (
    <Button variant="text" size="sm">
      {serviceStore === SERVICE_STORE_REPORT_S3 ? 'CSV' : 'スプレッドシート'}
    </Button>
  );
};

export const formatDataList = ({
  lists = [],
  onClickDownload = () => {},
  isAllowedSettingReport,
  isHavePermissionDataExport,
  paidContract,
}) => {
  const rows = lists.map((report) => {
    const {
      id,
      name,
      type,
      service_store: serviceStore,
      status_type: statusType,
      result_type: resultType,
      result_error_code: resultErrorCode,
      email_info: email,
      period_start: periodStart,
      period_end: periodEnd,
      complete_datetime: completeDatetime,
      modify_user: modifyUser,
      modify_date: modifyDate,
      period_mode: periodMode,
      period_interval: periodInterval,
      period_receive_report_day: periodReceiveReport,
      is_csv_expired: isCsvExpried,
      spreadsheet_link: spreadsheetLink,
      cross_device: crossDevice,
    } = report;
    const emailInfo = email ? JSON.parse(email) : [];
    const isBatchRunning = [STATUS_TYPE_WAITING, STATUS_TYPE_RUNNING].includes(
      statusType
    );
    let version = '';
    if (isHavePermissionDataExport) {
      const isRegularReport = checkIsRegularReport(type);
      version = (
        <Button variant={isRegularReport ? 'regular-report' : 'data-export'}>
          {isRegularReport ? '無償版' : '有償版'}
        </Button>
      );
    }

    const row = {
      rowId: id,
      view: isBatchRunning && isEmpty(completeDatetime) ? '' : '表示',
      name,
      type: (
        <>
          {REPORT_TYPES[type]}
          {version}
        </>
      ),
      output: getOutputReport({
        resultType,
        statusType,
        onClickDownload,
        serviceStore,
        isCsvExpried,
        spreadsheetLink,
        type,
        id,
        paidContract,
        crossDevice,
      }),
      status: getStatusReport({
        statusType,
        resultType,
        resultErrorCode,
        emailInfo,
        serviceStore,
        isCsvExpried,
        id,
        paidContract,
        crossDevice,
      }),
      periodTime: formatPeriodTime({
        periodStart,
        periodEnd,
        periodMode,
        periodInterval,
        periodReceiveReport,
        type,
      }),
      modifyDate,
      modifyUser,
      completeDatetime,
      isDisabledCheckbox: !isAllowedSettingReport || isBatchRunning,
      tooltipCheckbox: !isAllowedSettingReport ? MSG_NO_PERMISSION_SETTING : '',
      isDisabledEdit: !isAllowedSettingReport,
      tooltipEdit: !isAllowedSettingReport ? MSG_NO_PERMISSION_SETTING : '',
    };
    return row;
  });
  return rows;
};

export const formatDisplayEmailInfo = (emailInfo = []) => {
  if (emailInfo === NO_EXIST_EMAIL) {
    return '';
  }
  if (emailInfo?.length === 1) {
    return emailInfo[0].email;
  }
  if (emailInfo?.length > 1) {
    const email = emailInfo.reduce(
      (obj, item, index) => {
        const result = obj;
        if (index === 0) {
          result.displayEmail = item.email;
        } else {
          result.tooltipEmail = (
            <>
              {result.tooltipEmail} {isEmpty(result.tooltipEmail) ? '' : <br />}
              {item.email}
            </>
          );
        }
        return result;
      },
      {
        displayEmail: '',
        tooltipEmail: '',
      }
    );
    return (
      <div className="multiple-emails">
        <span>{email.displayEmail}</span>
        <DragonBall
          svg={<IconMultiplePerson />}
          variant="modal-white"
          placement="bottom-end"
        >
          {email.tooltipEmail}
        </DragonBall>
      </div>
    );
  }

  return '';
};

const formatStringDate = (string = '') => string.replace(/[/-]/g, '');

export const formatDataListViewHistory = (data = [], isSettingSystem) => {
  const rows = data.map((report) => {
    const {
      name,
      service_store: serviceStore,
      result_type: resultType,
      result_error_code: resultErrorCode,
      email_info: email,
      period_start: periodStart,
      period_end: periodEnd,
      complete_datetime: completeDatetime,
      send_email_mode: sendEmailMode,
    } = report;
    const emailInfo = email ? JSON.parse(email) : [];
    const row = {
      name: `${name}_${formatStringDate(periodStart)}-${formatStringDate(
        periodEnd
      )}`,
      periodTime: `${periodStart}-${periodEnd}`,
      completeDatetime,
      status: getStatusReportHistory({
        resultType,
        resultErrorCode,
        sendEmailMode,
        serviceStore,
        emailInfo,
      }),
    };
    if (isSettingSystem) {
      row.emailInfo = formatDisplayEmailInfo(emailInfo);
    }
    return row;
  });
  return rows;
};

export const formatUsersData = (users) => {
  if (isEmpty(users)) return [];

  const icon = <i className="fas fa-check" />;
  return users.map((user) => {
    const { agent_flag: agentFlag, user_roles: userRoles } = user;

    let email = user.email || '';
    const emails = email.split(',').map((item) => ({ email: item }));
    if (emails.length > 1) {
      email = formatDisplayEmailInfo(emails);
    }

    return {
      ...user,
      email,
      agent: agentFlag ? icon : null,
      rowId: user.user_id,
      view_ad: userRoles.includes(ROLE_VIEW_AD) ? icon : null,
      view_all:
        userRoles.includes(ROLE_VIEW_ALL) ||
        (userRoles.includes(ROLE_VIEW_ALL_AD_ONLY) && agentFlag)
          ? icon
          : null,
    };
  });
};

export const customizeDisplayName = (items, display) => {
  return items.map((item) => ({
    ...item,
    display_name: display[item.field_name] || item.display_name,
  }));
};

export const getGroupByReportType = (type) => {
  if (isEmpty(type)) {
    return [
      DISPLAYITEM_GROUP_CHANNEL,
      DISPLAYITEM_GROUP_TIME,
      DISPLAYITEM_GROUP_CONVERSION,
      DISPLAYITEM_GROUP_SITE,
      DISPLAYITEM_GROUP_CV_ATTRIBUTE,
    ];
  }
  if (type === REPORT_TYPE_ACCESS) {
    return [DISPLAYITEM_GROUP_TIME, DISPLAYITEM_GROUP_SITE];
  }
  if (type === REPORT_TYPE_CV_ATTRIBUTE) {
    return [DISPLAYITEM_GROUP_CV_ATTRIBUTE];
  }
  return [
    DISPLAYITEM_GROUP_CHANNEL,
    DISPLAYITEM_GROUP_TIME,
    DISPLAYITEM_GROUP_CONVERSION,
  ];
};

export const convertDisplayItemToGroup = (items) => {
  if (isEmpty(items)) return {};

  return items.reduce((acc, item) => {
    const { group_name: groupName } = item;
    return {
      ...acc,
      [groupName]: groupName in acc ? [...acc[groupName], item] : [item],
    };
  }, {});
};

export const formatDisplayItemByReportTypes = (items, types) => {
  if (isEmpty(types)) return {};

  const group = convertDisplayItemToGroup(items);

  return types.reduce((acc, type) => {
    const groups = getGroupByReportType(type);
    const fixedItems = DISPLAYITEM_FIXED[type] || [];

    return {
      ...acc,
      [type]: {
        default: items
          .filter(
            (item) => item.default_flag && groups.includes(item.group_name)
          )
          .map((item) => item.id),
        groups: groups
          .filter((name) => group[name])
          .map((name) => ({
            name,
            items: group[name].map((item) => ({
              ...item,
              fixed: fixedItems.includes(item.field_name),
            })),
          })),
      },
    };
  }, {});
};

export const getServiceStores = ({
  isEdit = false,
  defaultReportType,
  currentReportType,
  isAllowedSettingAdrepoReport,
  isAllowRegisterAdrepoReport,
  isAllowedSettingNormalReport,
  isAllowRegisterNormalReport,
  isAllowedSettingDataExport,
  statusSystemSync,
}) => {
  let services = Object.keys(SERVICE_STORES);
  // Allow setting regular report + data export, not allow adrepo report
  if (isAllowedSettingNormalReport && !isAllowedSettingAdrepoReport) {
    services = services.filter((service) => service !== REPORT_TYPE_AD_REPO);
  }
  // Allow setting adrepo report, not allow regular report + data export
  if (isAllowedSettingAdrepoReport && !isAllowedSettingNormalReport) {
    services = services.filter((service) => service === REPORT_TYPE_AD_REPO);
  }

  const isRegularReport = checkIsRegularReport(currentReportType);
  const isDefaultAdrepo = defaultReportType === REPORT_TYPE_AD_REPO;

  return services.map((key) => {
    const isStoreAdrepo = key === REPORT_TYPE_AD_REPO;

    let messageTooltipDisabled = '';

    let disabled =
      (isStoreAdrepo && !isAllowRegisterAdrepoReport) ||
      (!isStoreAdrepo && !isAllowRegisterNormalReport);

    if (isEdit) {
      disabled =
        (isDefaultAdrepo && !isStoreAdrepo) ||
        (!isDefaultAdrepo && isStoreAdrepo);
    }

    if (key === SERVICE_STORE_REPORT_GOOGLE_DRIVE) {
      disabled = disabled || isRegularReport || !isAllowedSettingDataExport;
      if (!isAllowedSettingDataExport) {
        messageTooltipDisabled = '無償版では利用できません';
      } else if (isRegularReport) {
        messageTooltipDisabled =
          'このレポートタイプはGoogleスプレッドシートに対応していません';
      }
    }
    if (!statusSystemSync && isStoreAdrepo) {
      disabled = true;
      messageTooltipDisabled = MESSAGE_DISABLED_SYSTEM_SYC;
    }
    return { ...SERVICE_STORES[key], disabled, messageTooltipDisabled };
  });
};

export const getStatusDisabledReportType = ({
  currentType,
  selectedType,
  isActionUpdate,
  isHavePermissionChannelAccess,
  isAllowRegisterRegularReport,
  isAllowRegisterDataExport,
  statusSystemSync,
}) => {
  const isRegularReport = checkIsRegularReport(currentType);
  const isDataExport = checkIsDataExport(currentType);

  if (currentType === REPORT_TYPE_ACCESS && !isHavePermissionChannelAccess) {
    return true;
  }

  if (isActionUpdate) {
    if (isRegularReport && checkIsDataExport(selectedType)) {
      return true;
    }
    if (isDataExport && checkIsRegularReport(selectedType)) {
      return true;
    }
  } else if (isRegularReport) {
    return !isAllowRegisterRegularReport;
  } else if (isDataExport) {
    return !statusSystemSync || !isAllowRegisterDataExport;
  }

  return false;
};

export const getTooltipMessageForReportType = ({
  reportType,
  isHavePermissionChannelAccess,
  statusSystemSync,
}) => {
  if (reportType === REPORT_TYPE_ACCESS && !isHavePermissionChannelAccess) {
    return 'ご指定のレポートを作成する契約または機能権限がありません。';
  }
  if (!statusSystemSync && checkIsDataExport(reportType)) {
    return MESSAGE_DISABLED_SYSTEM_SYC;
  }
  return '';
};

export const getReportTypes = ({
  isActionUpdate,
  reportType,
  isAllowedSettingDataExport,
  isAllowRegisterDataExport,
  isAllowRegisterRegularReport,
  isHavePermissionChannelAccess,
  statusSystemSync,
}) => {
  const types = isAllowedSettingDataExport
    ? [...REPORT_TYPES_DATA_EXPORT, ...REPORT_TYPES_REGULAR_REPORT]
    : REPORT_TYPES_REGULAR_REPORT;

  return types.reduce(
    (acc, type) => {
      const key = checkIsRegularReport(type) ? 'regularReports' : 'dataExports';
      const item = REPORT_TYPES_FOR_SETTING[type];
      return {
        ...acc,
        [key]: [
          ...acc[key],
          {
            label: item.label,
            value: item.value,
            disabled: getStatusDisabledReportType({
              currentType: type,
              selectedType: reportType,
              isActionUpdate,
              isHavePermissionChannelAccess,
              isAllowRegisterRegularReport,
              isAllowRegisterDataExport,
              statusSystemSync,
            }),
            tooltip: getTooltipMessageForReportType({
              reportType: type,
              isHavePermissionChannelAccess,
              statusSystemSync,
            }),
          },
        ],
      };
    },
    { regularReports: [], dataExports: [] }
  );
};

const fieldsFilterExclude = [
  FILTERS.FILTER_KEY_MEMBER_NAME_EXCLUDE,
  FILTERS.FILTER_KEY_OTHER1_EXCLUDE,
  FILTERS.FILTER_KEY_OTHER2_EXCLUDE,
  FILTERS.FILTER_KEY_OTHER3_EXCLUDE,
  FILTERS.FILTER_KEY_OTHER4_EXCLUDE,
  FILTERS.FILTER_KEY_OTHER5_EXCLUDE,
  FILTERS.FILTER_KEY_AD_ID_EXCLUDE,
  FILTERS.FILTER_KEY_AD_NAME_EXCLUDE,
  FILTERS.FILTER_KEY_MEDIA_EXCLUDE,
  FILTERS.FILTER_KEY_AD_GROUP1_EXCLUDE,
  FILTERS.FILTER_KEY_AD_GROUP2_EXCLUDE,
];

const fieldsOperatorIncludeOrExact = [
  FILTERS.FILTER_KEY_MEMBER_NAME,
  FILTERS.FILTER_KEY_OTHER1,
  FILTERS.FILTER_KEY_OTHER2,
  FILTERS.FILTER_KEY_OTHER3,
  FILTERS.FILTER_KEY_OTHER4,
  FILTERS.FILTER_KEY_OTHER5,
  FILTERS.FILTER_KEY_SEARCH_WORD,
  FILTERS.FILTER_KEY_SEARCH_WORD_EXCLUDE,
];

// key (new system), value (old system)
const fieldsAddKeyMode = {
  [FILTERS.FILTER_KEY_AD_NAME]: FILTERS.FILTER_KEY_AD_NAME,
  [FILTERS.FILTER_KEY_AD_NOTE]: FILTERS.FILTER_KEY_AD_NOTE,
  [FILTERS.FILTER_KEY_LINK_URL]: FILTERS.FILTER_KEY_LINK_URL,
  [FILTERS.FILTER_KEY_LANDING_PAGE_URL]:
    FILTER_KEY_MAPPER[FILTERS.FILTER_KEY_LANDING_PAGE_URL],
  [FILTERS.FILTER_KEY_PAGE_TITLE]:
    FILTER_KEY_MAPPER[FILTERS.FILTER_KEY_PAGE_TITLE],
};

const fieldsContactHistory = [
  FILTERS.FILTER_KEY_CONTACT_HISTORY_AD,
  FILTERS.FILTER_KEY_CONTACT_HISTORY_SEARCH,
  FILTERS.FILTER_KEY_CONTACT_HISTORY_PAGE,
];

const filterableList = Object.keys(allFilterableList).reduce((acc, field) => {
  const { allowScreen = [] } = allFilterableList[field];
  if (isEmpty(allowScreen) || allowScreen.includes(DATA_EXPORT)) {
    return [...acc, { ...allFilterableList[field], field }];
  }
  return acc;
}, []);

const getValueMasterdataById = (masterdata, key, id) => {
  const keyConverted = {
    conv_id: 'cv',
    conversion_ids: 'cv',
    media_id: 'media',
    ad_media_ids_selected: 'media',
    ad_group1_id: 'adGroup1',
    ad_group1_ids_selected: 'adGroup1',
    ad_group2_id: 'adGroup2',
    ad_group2_ids_selected: 'adGroup2',
    content_category_id: 'contentCategory',
    om_page_category_ids_selected: 'contentCategory',
  }[key];

  return masterdata[keyConverted]?.[id]?.name;
};

export const prepareFilterForUI = ({
  filters,
  reportType,
  masterdata,
  permissions,
}) => {
  if (reportType === REPORT_TYPE_ACCESS) return {};
  // Swap key value
  const filterOperatorMapperInvert = invert(FILTER_OPERATOR_MAPPER);
  const filterContactHistoryMapperInvert = invert(
    FILTER_CONTACT_HISTORY_MAPPER
  );

  // convert filter to display UI
  return filterableList.reduce((acc, filterable) => {
    const { type, field } = filterable;
    const fieldFrom = `${field}_from`;
    const fieldTo = `${field}_to`;
    const key = FILTER_KEY_MAPPER[field] || field;
    const filter = fieldsFilterExclude.includes(field)
      ? filters.filter
      : filters;

    let ignoreFilter =
      isEmpty(filter) || !(key in filter) || isStringEmpty(filter[key]);

    if (
      [FILTERS.FILTER_KEY_AMOUNT, FILTERS.FILTER_KEY_AD_DATE].includes(field)
    ) {
      ignoreFilter =
        isStringEmpty(filter[fieldFrom]) && isStringEmpty(filter[fieldTo]);
    }

    if (ignoreFilter) return acc;

    const value = filter[key];
    let ids = [];
    let values = value;
    let option = null;

    if (type === 0) {
      values = value.reduce((accContactHistoty, item) => {
        if (item !== 'indirect') {
          return [...accContactHistoty, filterContactHistoryMapperInvert[item]];
        }
        return accContactHistoty;
      }, []);
    } else if (type === 1) {
      ids = value;
      values = ids.map((id) =>
        id === UNREGISTERED_ID_FILTER_KEY
          ? UNREGISTERED_DISPLAY_VALUE
          : getValueMasterdataById(masterdata, key, id)
      );
    } else if (type === 2) {
      option = FILTERS.FILTER_OPERATOR_EXACT;
      values = value.split(',');
      const fieldsAddKeyModeOldSystem = Object.values(fieldsAddKeyMode);
      if (fieldsAddKeyModeOldSystem.includes(key)) {
        option = Number(filterOperatorMapperInvert[filter[`${key}_mode`]]);
      } else if (fieldsOperatorIncludeOrExact.includes(key)) {
        option = null;
        values = Object.values(
          values.reduce((accValue, item) => {
            let keyByOperator = FILTERS.FILTER_OPERATOR_INCLUDE;
            let valueByOperator = item;
            if (item.charAt(0) === '"' && item.slice(-1) === '"') {
              keyByOperator = FILTERS.FILTER_OPERATOR_EXACT;
              valueByOperator = item.slice(1, -1);
            }
            return {
              ...accValue,
              [keyByOperator]: {
                option: keyByOperator,
                values: accValue[keyByOperator]?.values
                  ? [...accValue[keyByOperator].values, valueByOperator]
                  : [valueByOperator],
              },
            };
          }, {})
        );
      }
    } else if (type === 3) {
      ids = value;
      if (field === FILTERS.FILTER_KEY_CHANNELS) {
        ids = FilterService.getMaster(field).reduce((accMaster, item) => {
          const id = FILTER_CHANNEL_MAPPER[item.key];
          if (
            value.includes(id) &&
            (!('permissionSet' in item) ||
              resolve(item.permissionSet[reportType], permissions))
          ) {
            return [...accMaster, item.key];
          }

          return accMaster;
        }, []);
        ignoreFilter = ids.length === 0;
      }

      values = FilterService.getMaster(field).reduce((accMaster, item) => {
        if (!ids.includes(item.key)) return accMaster;
        return [...accMaster, item.value];
      }, []);
    } else if ([5, 6].includes(type)) {
      values = [filter[fieldFrom], filter[fieldTo]];
    }

    if (ignoreFilter) return acc;

    return {
      ...acc,
      [field]: { ids, values, option, type },
    };
  }, {});
};

export const prepareFilterForUIFromView = ({
  filters,
  reportType,
  masterdata,
  permissions,
}) => {
  const filterKeyMapper = {
    channel_access_type: 'channel_access_types',
    amount_max: 'amount',
    amount_min: 'amount',
  };

  const filter = filters.reduce((acc, item) => {
    const { field } = item;
    const key = filterKeyMapper[field] || field;
    if (key === 'amount') {
      return { ...acc, [key]: { ...acc[key], [field]: item.value || '' } };
    }
    if (key === 'contact_history') {
      return {
        ...acc,
        contact_history_ad: item,
        contact_history_search: item,
        contact_history_page: item,
      };
    }
    return { ...acc, [key]: item };
  }, {});

  return filterableList.reduce((acc, filterable) => {
    const { type, field } = filterable;
    if (!(field in filter)) return acc;

    const { value } = filter[field];
    let { operator = null } = filter[field];

    let ids = [];
    let values = value;
    let ignoreFilter = false;

    if (type === 1) {
      ids = values;
      values = ids.map((id) =>
        id === UNREGISTERED_ID_FILTER_KEY
          ? UNREGISTERED_DISPLAY_VALUE
          : getValueMasterdataById(masterdata, field, id)
      );
    } else if (type === 2) {
      const fieldsAddKeyModeNewSystem = Object.keys(fieldsAddKeyMode);
      // ignore filter has operator is not allowed
      if (fieldsOperatorIncludeOrExact.includes(field)) {
        ignoreFilter = ![
          FILTERS.FILTER_OPERATOR_INCLUDE,
          FILTERS.FILTER_OPERATOR_EXACT,
        ].includes(operator);
        if (!ignoreFilter) {
          values = [{ option: operator, values }];
          operator = null;
        }
      } else if (!fieldsAddKeyModeNewSystem.includes(field)) {
        ignoreFilter = operator !== FILTERS.FILTER_OPERATOR_EXACT;
      }
    } else if (type === 3) {
      const master = FilterService.getMaster(field);

      ids = master.reduce((accMaster, item) => {
        if (
          value.includes(item.key) &&
          (!('permissionSet' in item) ||
            resolve(item.permissionSet[reportType], permissions))
        ) {
          return [...accMaster, item.key];
        }

        return accMaster;
      }, []);
      values = master.reduce((accMaster, item) => {
        if (ids.includes(item.key)) return [...accMaster, item.value];
        return accMaster;
      }, []);
    } else if (type === 5) {
      values = [filter[field][`${field}_min`], filter[field][`${field}_max`]];
    }

    if (ignoreFilter) {
      return acc;
    }

    return { ...acc, [field]: { type, ids, values, option: operator } };
  }, {});
};

export const prepareFilterForApi = (filters, filterable) => {
  return FilterService.prepareForApi(filters).reduce((acc, filter) => {
    const { field, value, operator } = filter;
    let ignoreFilter = false;
    let actualValue = value;

    switch (field) {
      case 'amount_min':
      case 'amount_max':
        ignoreFilter = !filterable[FILTERS.FILTER_KEY_AMOUNT];
        break;

      case 'ad_date_min':
      case 'ad_date_max':
        ignoreFilter = !filterable[FILTERS.FILTER_KEY_AD_DATE];
        actualValue = formatStringDate(value);
        break;

      default:
        ignoreFilter = !filterable[field];
        break;
    }

    if (ignoreFilter) return acc;

    const key = FILTER_KEY_MAPPER[field] || field;

    if (operator) {
      actualValue = value.join(',');
    }

    if (fieldsOperatorIncludeOrExact.includes(key)) {
      actualValue = value
        .reduce((accValues, item) => {
          let { values } = item;
          if (item.option === FILTERS.FILTER_OPERATOR_EXACT) {
            values = item.values.map((str) => `"${str}"`);
          }
          return [...accValues, ...values];
        }, [])
        .join(',');
    }

    if (field === FILTERS.FILTER_KEY_CHANNELS && value) {
      actualValue = value.map((item) => FILTER_CHANNEL_MAPPER[item]);
    }

    const newFilter = { [key]: actualValue };
    const fieldsAddKeyModeOldSystem = Object.values(fieldsAddKeyMode);
    if (fieldsAddKeyModeOldSystem.includes(key)) {
      newFilter[`${key}_mode`] = FILTER_OPERATOR_MAPPER[operator];
    }

    if (fieldsContactHistory.includes(field)) {
      if (isEmpty(actualValue)) {
        ignoreFilter = true;
      } else {
        newFilter[key] = actualValue.map(
          (item) => FILTER_CONTACT_HISTORY_MAPPER[item]
        );
        const isHasIndirect = actualValue.some((item) =>
          Object.keys(FILTER_INDIRECT_MAPPER).includes(item)
        );
        if (isHasIndirect) {
          newFilter[key].push('indirect');
        }
      }
    }

    if (ignoreFilter) return acc;

    if (fieldsFilterExclude.includes(field)) {
      return { ...acc, filter: { ...(acc.filter || {}), ...newFilter } };
    }

    return { ...acc, ...newFilter };
  }, {});
};

export const prepareFilterForUIRegularReport = ({
  type,
  channel,
  masterdata,
  permissions,
  filters,
}) => {
  const pageId = getPageIdByReportType(type);

  const filtersNormalize = FilterService.filterNonContractValues(
    ViewService.filterNormalize(filters, masterdata),
    permissions,
    pageId,
    channel
  );

  return FilterService.prepareForUI(filtersNormalize, pageId, channel);
};

const prepareFilterForApiRegularReport = ({
  type = '',
  channel = '',
  permissions = [],
  filters = {},
  filterable = {},
}) => {
  // Get only filters that are in the list and Add permission information for each filter
  const filterValues = Object.keys(filters).reduce((acc, key) => {
    if (
      key in filterable ||
      (key === FILTERS.FILTER_KEY_CHANNEL_DISPLAY_RANGE &&
        type === REPORT_TYPE_CONVERSION)
    ) {
      return { ...acc, [key]: { ...filterable[key], ...filters[key] } };
    }
    return acc;
  }, {});

  const pageId = getPageIdByReportType(type);

  // Only receive allowed filters
  const contractFilteredValues = FilterService.filterNonContractValues(
    FilterService.prepareForUI(filterValues, pageId, channel),
    permissions,
    pageId,
    channel
  );

  // Format value for api
  return FilterService.prepareForRequestBody({
    pageId,
    filters: FilterService.prepareForApi(contractFilteredValues),
  });
};

export const masterDataIdsSelected = (data) => {
  if (checkIsRegularReport(data.type)) {
    return FilterService.getMasterDataIdsSelected(data.filters);
  }

  const {
    conversion_ids: conversionIds = [],
    ad_media_ids_selected: mediaIdsInclude = [],
    ad_group1_ids_selected: adgroup1IdsInclude = [],
    ad_group2_ids_selected: adgroup2IdsInclude = [],
    om_page_category_ids_selected: contentCategoryIds = [],
    filter: {
      ad_media_ids_selected: mediaIdsExclude = [],
      ad_group1_ids_selected: adgroup1IdsExclude = [],
      ad_group2_ids_selected: adgroup2IdsExclude = [],
    } = {},
  } = data.quick_search || {};

  const masterDataIds = {
    conv_id: conversionIds,
    content_category_id: contentCategoryIds,
    media_id: [],
    ad_group1_id: [],
    ad_group2_id: [],
  };

  if ([REPORT_TYPE_CHANNEL, REPORT_TYPE_CV_ATTRIBUTE].includes(data.type)) {
    const ids = {
      media_id: [...mediaIdsInclude, ...mediaIdsExclude],
      ad_group1_id: [...adgroup1IdsInclude, ...adgroup1IdsExclude],
      ad_group2_id: [...adgroup2IdsInclude, ...adgroup2IdsExclude],
    };

    Object.keys(ids).forEach((key) => {
      masterDataIds[key] = ids[key].filter((item, index) => {
        return ids[key].indexOf(item) === index && item !== 0;
      });
    });
  }

  return masterDataIds;
};

export const prepareDisplayItemForUIRegularReport = ({
  type,
  items,
  settings,
}) => {
  if (isEmpty(items) || isEmpty(settings)) return settings;

  const newSettings = Object.keys(items).reduce((acc, key) => {
    return { ...acc, [key]: items[key].ebisRequired || !!settings[key] };
  }, {});

  if (
    [
      REPORT_TYPE_CATEGORY,
      REPORT_TYPE_PERIOD,
      REPORT_TYPE_CATEGORY_PERIOD,
    ].includes(type)
  ) {
    return DisplayItemsService.getSettingFallback({
      items,
      settings: newSettings,
    });
  }

  return newSettings;
};

export const prepareSettingReportForUI = (
  data,
  masterdata,
  permissions,
  displayItem
) => {
  const {
    [REPORT_TYPE]: type,
    [REPORT_CHANNEL]: channel,
    [QUICK_SEARCH]: quickSearch,
  } = data;

  // report type adrepo
  if (type === REPORT_TYPE_AD_REPO) {
    const { channel_access_type: channels = [] } = quickSearch;
    const permissionDef = {
      [FILTER_CHANNEL_MAPPER[CHANNEL_TYPE_ID.VIEW]]: CHANNEL_VIEW_PERMISSIONS,
    };
    return {
      ...data,
      [QUICK_SEARCH]: {
        ...quickSearch,
        channel_access_type: channels.filter((key) => {
          return (
            !(key in permissionDef) || resolve(permissionDef[key], permissions)
          );
        }),
      },
    };
  }

  const defaultPeriod = moment().format(CALENDAR_DAY_FORMAT);
  const isRegularReport = checkIsRegularReport(type);

  // common for report regular report & data export
  const response = {
    ...data,
    [WAIT_COST]: data[WAIT_COST] ? WAIT_COST_ON : WAIT_COST_OFF,
    [PERIOD_START]: data[PERIOD_START] || defaultPeriod,
    [PERIOD_END]: data[PERIOD_END] || defaultPeriod,
    [PERIOD_INTERVAL]: data[PERIOD_INTERVAL] || EMAIL_TITLE_MODE_DEFAULT,
    [PERIOD_RECEIVE_REPORT_DAY]:
      data[PERIOD_RECEIVE_REPORT_DAY] || WEEKDAY_MONDAY,
    [EMAIL_TITLE_MODE]: data[EMAIL_TITLE_MODE] || STATUS_PERIOD_INTERVAL_DAY,
    [DISPLAY_HEADER]: !data[DISPLAY_HEADER],
  };

  // report type regular report
  if (isRegularReport) {
    return {
      ...response,
      [SUMMARY_MODE]: data[SUMMARY_MODE] || TIME_AXIS_DAILY,
      [SORT]: getSortObject(data[SORT]),
      [REPORT_FIELDS.FILTERS]: prepareFilterForUIRegularReport({
        type,
        channel,
        masterdata,
        permissions,
        filters: data[REPORT_FIELDS.FILTERS],
      }),
      [DISPLAY_ITEMS]: prepareDisplayItemForUIRegularReport({
        type,
        items: displayItem[type]?.[channel]?.item,
        settings: data[DISPLAY_ITEMS],
      }),
    };
  }

  // report type data export
  return {
    ...response,
    [DIMENSIONS]: data[DIMENSIONS] || [],
    [METRICS]: data[METRICS] || [],
    [CONTACT_HISTORY]:
      type === REPORT_TYPE_CV_ATTRIBUTE
        ? data[CONTACT_HISTORY]
        : CONTACT_HISTORIES.map((item) => item.name),
    [QUICK_SEARCH]: prepareFilterForUI({
      filters: data[QUICK_SEARCH],
      reportType: type,
      masterdata,
      permissions,
    }),
    [GOOGLE_ACCOUNT]: isEmpty(data[GOOGLE_ACCOUNT])
      ? null
      : data[GOOGLE_ACCOUNT],
    [GOOGLE_DIR_PATH]:
      data[GOOGLE_ACCOUNT]?.google_dir_path || GOOGLE_DRIVE_DIR_PATH,
    [GOOGLE_SAVE_MODE]: data[GOOGLE_ACCOUNT]?.google_save_mode || 'new',
  };
};

export const getFieldError = (field) => {
  if (
    [PERIOD_MODE, PERIOD_INTERVAL, PERIOD_RECEIVE_REPORT_DAY].includes(field)
  ) {
    return PERIOD;
  }
  if ([DISPLAY_TOTAL, DISPLAY_ROW_ZERO, DISPLAY_HEADER].includes(field)) {
    return DISPLAY;
  }
  if ([SEND_EMAIL_MODE, EMAIL_TITLE_MODE].includes(field)) {
    return EMAIL;
  }

  return field;
};

export const validateDataForPaidContract = (isWarning) => {
  return {
    cross_device: isWarning,
  };
};

export const validateDataSetting = (data) => {
  const {
    [REPORT_TYPE]: reportType,
    [PERIOD_MODE]: periodMode,
    [PERIOD_END]: periodEnd,
    [SEND_EMAIL_MODE]: sendEmailMode,
    [EMAIL_TITLE_MODE]: emailTitleMode,
    [SERVICE_STORE]: serviceStore,
    [SORT]: sort,
    [DISPLAY_ITEMS]: displayItems,
  } = data;

  let ruleValidate = { ...commonRules };
  if (checkIsRegularReport(reportType)) {
    ruleValidate = { ...ruleValidate, ...regularReportRules };
    if (!isEmpty(sort)) {
      const accepts = Object.keys(displayItems).filter(
        (key) => displayItems[key]
      );
      // validation for case user can select sort item belong to dimension axis
      if (
        reportType === REPORT_TYPE_PERIOD &&
        accepts.includes(DisplayItems.PERIOD) &&
        accepts.some((item) =>
          [
            DisplayItems.CATEGORY,
            DisplayItems.CHANNEL,
            DisplayItems.SYNC_CATEGORY,
          ].includes(item)
        )
      ) {
        const displayItemsDimensions = [
          DisplayItems.CATEGORY,
          DisplayItems.CHANNEL,
          DisplayItems.SYNC_CATEGORY,
        ];
        const acceptValuesValidate = accepts.filter(
          (value) => !displayItemsDimensions.includes(value)
        );
        ruleValidate[SORT] = [
          acceptsValue(
            [...acceptValuesValidate, DisplayItems.PERIOD],
            '表示項目を設定してください'
          ),
        ];
      } else {
        ruleValidate[SORT] = [
          acceptsValue(
            [...accepts, DisplayItems.PERIOD],
            '表示項目を設定してください'
          ),
        ];
      }
    }
  } else if (checkIsDataExport(reportType)) {
    ruleValidate = { ...ruleValidate, ...dataExportRules };
    if (reportType !== REPORT_TYPE_CV_ATTRIBUTE) {
      ruleValidate = {
        ...ruleValidate,
        ...metricRules,
        [DIMENSIONS]: [
          ...ruleValidate[DIMENSIONS],
          maxLine(MAX_DIMENSION, '選択できる集計軸は最大{max}件です。'),
        ],
      };
    }
    if (serviceStore === SERVICE_STORE_GOOGLE_DRIVE) {
      ruleValidate = { ...ruleValidate, ...googleAccountRules };
    }
  }

  if (sendEmailMode) {
    ruleValidate = { ...ruleValidate, ...emailTitleModeRules };
    if (emailTitleMode === EMAIL_TITLE_MODE_CUSTOM) {
      ruleValidate = { ...ruleValidate, ...emailTitleRules };
    }
  }

  if (periodMode === STATUS_PERIOD_MODE_NORMAL) {
    ruleValidate = { ...ruleValidate, ...periodRules };
  } else {
    ruleValidate = { ...ruleValidate, ...periodIntervalRules };
  }

  if (periodMode === STATUS_PERIOD_MODE_NORMAL && periodEnd) {
    ruleValidate = {
      ...ruleValidate,
      [REPORT_FIELDS.PERIOD_START]: [
        ...ruleValidate[REPORT_FIELDS.PERIOD_START],
        isBetweenPeriod(
          moment(periodEnd).subtract(3, 'month').format(CALENDAR_DAY_FORMAT),
          moment(periodEnd).format(CALENDAR_DAY_FORMAT),
          '集計期間は、3か月以内で設定してください。'
        ),
      ],
    };
  }

  const error = chain(data)
    .mapValues((value) => ({ value: value || null }))
    .mapValues((value, field) => {
      if (isEmpty(ruleValidate[field])) return [];

      const newValue = field === SORT ? { value: value.value.field } : value;
      const rulesByField = [...ruleValidate[field]];
      return rulesByField
        .map((rule) => rule(newValue))
        .filter((rule) => !isEmpty(rule));
    })
    .pickBy((errors) => !isEmpty(errors))
    .mapValues((errors) => errors[0])
    .value();

  return Object.keys(error).reduce((acc, field) => {
    const newField = getFieldError(field);
    return { ...acc, [newField]: error[field] };
  }, {});
};

export const formatDataToRequest = (data, filterable, permissions) => {
  const {
    google_dir_path: googleDirPath,
    google_save_mode: googleSaveMode,
    ...restReport
  } = data;

  const dataFormat = {
    ...restReport,
    [GOOGLE_ACCOUNT]: {
      ...restReport.google_account,
      google_dir_path: googleDirPath,
      google_save_mode: googleSaveMode,
    },
  };

  const {
    [REPORT_TYPE]: type,
    [SERVICE_STORE]: serviceStore,
    [PERIOD_MODE]: periodMode,
    [SEND_EMAIL_MODE]: sendEmailMode,
    [REPORT_CHANNEL]: channel,
  } = dataFormat;

  const { ignoreFields = [] } = REPORT_TYPES_FOR_SETTING[type];

  return Object.keys(dataFormat).reduce((acc, key) => {
    if (
      ignoreFields.includes(key) ||
      (key === GOOGLE_ACCOUNT && serviceStore !== SERVICE_STORE_GOOGLE_DRIVE)
    ) {
      return acc;
    }

    let value = dataFormat[key];

    switch (key) {
      case PERIOD_START:
      case PERIOD_END:
        if (periodMode === STATUS_PERIOD_MODE_PERIODIC) {
          value = '';
        } else {
          value = moment(value).format(CALENDAR_DAY_FORMAT);
        }
        break;
      case EMAIL_TITLE_MODE:
        if (!sendEmailMode) {
          value = EMAIL_TITLE_MODE_DEFAULT;
        }
        break;
      case EMAIL_TITLE:
        if (
          !sendEmailMode ||
          dataFormat[EMAIL_TITLE_MODE] === EMAIL_TITLE_MODE_DEFAULT
        ) {
          value = '';
        }
        break;
      case WAIT_COST:
        if (periodMode === STATUS_PERIOD_MODE_NORMAL) {
          value = WAIT_COST_OFF;
        }
        break;
      case CONTACT_HISTORY:
        if (type !== REPORT_TYPE_CV_ATTRIBUTE) {
          value = [];
        }
        break;
      case DISPLAY_HEADER:
        value = !value;
        break;
      case QUICK_SEARCH:
        value = prepareFilterForApi(value, filterable);
        break;
      case REPORT_FIELDS.FILTERS:
        value = prepareFilterForApiRegularReport({
          type,
          channel,
          permissions,
          filterable,
          filters: value,
        });
        break;
      case GOOGLE_ACCOUNT:
        value = {
          ...value,
          [GOOGLE_DIR_PATH]: value[GOOGLE_DIR_PATH] || GOOGLE_DRIVE_DIR_PATH,
        };
        break;
      case SORT:
        value = isEmpty(value) ? '' : getSortString(value);
        break;

      default:
        break;
    }

    return { ...acc, [key]: value };
  }, {});
};

export const formatDataError = (errors) => {
  if (isEmpty(errors) || !isArray(errors)) return {};

  return errors.reduce((acc, error) => {
    const { field: fieldError } = error;
    let [field] = fieldError.split('.');

    if (isEmpty(ERROR_MESSAGE_BY_FIELD[field])) return acc;

    const { message, param } = ERROR_MESSAGE_BY_FIELD[field];

    const errorMessage = getErrorMessageByCode(error, message, param);

    field = getFieldError(field);

    return { ...acc, [field]: errorMessage };
  }, {});
};

export const getStatusSystemSync = (data = []) => {
  if (isEmpty(data)) {
    return false;
  }
  if (data?.length > 0 && !data[0].status) {
    return false;
  }
  return true;
};

export const getDisplayItemIdsByView = ({
  displayItems,
  view,
  maxItems = API_UNLIMITED_VALUE,
}) => {
  const items = displayItems.reduce((acc, group) => {
    return [
      ...acc,
      ...group.items
        .filter((item) => {
          const isChecked = (
            DISPLAYITEM_KEY_MAPPER[item.field_name] || []
          ).some((field) => view[field]);

          return item.fixed || isChecked || view[item.field_name];
        })
        .map((item) => item.id),
    ];
  }, []);

  return maxItems === API_UNLIMITED_VALUE ? items : items.slice(0, maxItems);
};

const mergeSettingLabels = (displayItem, display) => {
  return Object.keys(display).reduce((acc, key) => {
    if (displayItem[key]) {
      return { ...acc, [key]: { ...acc[key], title: display[key] } };
    }
    return acc;
  }, displayItem);
};

const getDisplayItemByPermissions = (item, channel, permissions) => {
  return Object.keys(item).reduce((acc, key) => {
    const permissionDef = get(item[key], `permissionSet.${channel}`);
    const isFieldMediaSync =
      permissionDef && permissionDef.permissions.includes(MEDIA_SYNC_VIEW);
    const permitted =
      !permissionDef ||
      (resolve(permissionDef, permissions) && !isFieldMediaSync);
    return { ...acc, [key]: permitted };
  }, {});
};

const getDisplayItemSettingDefault = (item, itemPermitted) => {
  return Object.keys(item).reduce((acc, key) => {
    if (itemPermitted[key]) {
      return { ...acc, [key]: !!item[key].displayDefault };
    }
    return acc;
  }, {});
};

const getDisplayItemAllowSetting = (item, itemPermitted) => {
  return Object.keys(item).reduce((acc, key) => {
    if (itemPermitted[key]) {
      return { ...acc, [key]: item[key] };
    }
    return acc;
  }, {});
};

const getDisplayItemAllowSortData = (item, itemPermitted, type) => {
  const defaultValue = {
    [DisplayItems.PERIOD]: {
      title: DisplayItems.initBase[DisplayItems.PERIOD].title,
      order: 0,
    },
  };
  return Object.keys(item)
    .filter((key) => {
      const { sortDisabled, denyEbis } = item[key];
      return itemPermitted[key] && !sortDisabled && !denyEbis;
    })
    .reduce(
      (acc, key) => ({
        ...acc,
        [key]: { title: item[key].title, order: item[key].order },
      }),
      checkIsReportTypePeriod(type) ? defaultValue : {}
    );
};

export const getMasterdataDisplayItem = ({
  permissions = [],
  display = {},
}) => {
  const channels = [CHANNEL_TYPE_AD, CHANNEL_TYPE_ALL];
  const types = REPORT_TYPES_REGULAR_REPORT;
  const displayItem = {
    [CHANNEL_TYPE_AD]: {
      [REPORT_TYPE_CATEGORY]: DisplayItemsService.getCategoryAnalysisAd(),
      [REPORT_TYPE_DETAIL]: DisplayItemsService.getDetailAnalysisAd(),
      [REPORT_TYPE_PERIOD]: DisplayItemsService.getPeriodAnalysisAd(),
      [REPORT_TYPE_CONVERSION]: DisplayItemsService.getCvAttributesAd(),
      [REPORT_TYPE_CATEGORY_PERIOD]: DisplayItemsService.getCategoryAnalysisAd(),
      [REPORT_TYPE_DETAIL_PERIOD]: DisplayItemsService.getDetailAnalysisAd(),
    },
    [CHANNEL_TYPE_ALL]: {
      [REPORT_TYPE_CATEGORY]: DisplayItemsService.getCategoryAnalysisAll(),
      [REPORT_TYPE_DETAIL]: DisplayItemsService.getDetailAnalysisAll(),
      [REPORT_TYPE_PERIOD]: DisplayItemsService.getPeriodAnalysisAll(),
      [REPORT_TYPE_CONVERSION]: DisplayItemsService.getCvAttributesAll(),
      [REPORT_TYPE_CATEGORY_PERIOD]: DisplayItemsService.getCategoryAnalysisAll(),
      [REPORT_TYPE_DETAIL_PERIOD]: DisplayItemsService.getDetailAnalysisAll(),
    },
  };

  return types.reduce((accType, type) => {
    return {
      ...accType,
      [type]: channels.reduce((accChannel, channel) => {
        const item = mergeSettingLabels(displayItem[channel][type], display);
        const itemPermitted = getDisplayItemByPermissions(
          item,
          channel,
          permissions
        );

        return {
          ...accChannel,
          [channel]: {
            item: getDisplayItemAllowSetting(item, itemPermitted),
            sortItem: getDisplayItemAllowSortData(item, itemPermitted, type),
            settingDefault: getDisplayItemSettingDefault(item, itemPermitted),
          },
        };
      }, {}),
    };
  }, {});
};

export const prepareView = (data) => {
  return ViewService.excludeBlacklist(data).sort(
    (a, b) => a[MEDIA_SYNC] - b[MEDIA_SYNC]
  );
};

// this function to filter list user can sort when select aggregation follow date
export const getSortableList = (
  displayItems = {},
  currentSortableList = {},
  reportType = ''
) => {
  let accepts = Object.keys(displayItems).filter((key) => displayItems[key]);
  if (reportType === REPORT_TYPE_PERIOD && displayItems.date) {
    accepts = accepts.filter(
      (item) =>
        ![
          DisplayItems.CATEGORY,
          DisplayItems.CHANNEL,
          DisplayItems.SYNC_CATEGORY,
          DisplayItems.AD_GROUP1,
          DisplayItems.AD_GROUP2,
        ].includes(item)
    );
  }
  const nextSortableList = Object.fromEntries(
    Object.entries(currentSortableList).filter(([key]) =>
      [...accepts, DisplayItems.PERIOD].includes(key)
    )
  );
  return nextSortableList;
};

export const isAcceptedAccounts = (accountStr = '') => {
  return true; // return True for apply all account
};
