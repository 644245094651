import {
  CAPI_SETTING_NAME,
  MEDIUM_ID,
  PIXEL_ID,
  ACCESS_TOKEN,
  CUSTOMER_ID,
  LINE_TAG_ID,
  LINE_TAG_ACCESS_TOKEN,
  YAHOO_ACCOUNT_ID,
  YAHOO_YDN_CONV_IO,
  PIXEL_CODE,
  EMAIL_NOTIFICATION,
  SEND_MAIL_USERS,
  CV_POINTS,
  X_PIXEL_ID,
  MICROSOFT_CUSTOMER_ID,
  MICROSOFT_CUSTOMER_ACCOUNT_ID,
} from 'domain/fields';
import {
  CAPI_NAME_MAX_LENGTH,
  acceptedMediumIds,
  acceptedEmailNotifications,
  FACEBOOK,
  GOOGLE,
  GOOGLE_OFFLINE,
  LINE,
  YAHOO_SEARCH_AD,
  YAHOO_DISPLAY_AD,
  YAHOO_CONVERSION_API,
  TIKTOK,
  X_CONVERSION_API,
  MICROSOFT,
} from 'domain/capi-setting/consts';
import {
  requiredValue,
  notContainSpaces,
  maxLength,
  acceptsValue,
  requiredCheck,
  notContainControlCharacters,
} from 'services/validations/commonValidations';

// Define the common rules for the CAPI Settings
export const capiSettingRules = {
  [CAPI_SETTING_NAME]: [
    requiredValue(),
    notContainSpaces(
      '設定名をスペース、タブ、改行のみで登録することはできません'
    ),
    maxLength(CAPI_NAME_MAX_LENGTH),
  ],
  [MEDIUM_ID]: [
    requiredValue(),
    acceptsValue(acceptedMediumIds, '連携先を選択してください。'),
  ],
  [EMAIL_NOTIFICATION]: [
    acceptsValue(
      acceptedEmailNotifications,
      'メール通知を正しく選択してください。'
    ),
  ],
  [SEND_MAIL_USERS]: [],
  [CV_POINTS]: [requiredValue()],
};

// Define the Credential rules
export const credentialRules = {
  [FACEBOOK]: {
    [PIXEL_ID]: [requiredValue()],
    [ACCESS_TOKEN]: [requiredValue()],
  },
  [GOOGLE]: {
    [CUSTOMER_ID]: [requiredValue()],
  },
  [GOOGLE_OFFLINE]: {
    [CUSTOMER_ID]: [requiredValue()],
  },
  [LINE]: {
    [LINE_TAG_ID]: [requiredValue()],
    [LINE_TAG_ACCESS_TOKEN]: [requiredValue()],
  },
  [YAHOO_SEARCH_AD]: {
    [YAHOO_ACCOUNT_ID]: [requiredValue()],
  },
  [YAHOO_DISPLAY_AD]: {
    [YAHOO_ACCOUNT_ID]: [requiredValue()],
  },
  [YAHOO_CONVERSION_API]: {
    [YAHOO_YDN_CONV_IO]: [
      requiredValue(),
      notContainSpaces(),
      maxLength(32),
      notContainControlCharacters(),
    ],
  },
  [TIKTOK]: {
    [PIXEL_CODE]: [requiredValue()],
    [ACCESS_TOKEN]: [requiredValue()],
  },
  [X_CONVERSION_API]: {
    [X_PIXEL_ID]: [requiredValue()],
  },
  [MICROSOFT]: {
    [MICROSOFT_CUSTOMER_ID]: [requiredValue()],
    [MICROSOFT_CUSTOMER_ACCOUNT_ID]: [requiredValue()],
  },
};

// Define the Send Mail rules
export const sendMailRules = {
  [SEND_MAIL_USERS]: [requiredCheck()],
};
