import Api, { SettingsApi, FbCApi } from 'services/api/Api';
import pages from 'services/routes/pages';
import {
  CAPI_SETTING,
  USER_MANAGEMENT,
  USER_ENDPOINT,
} from 'services/routes/constants';
import apiUtils from 'services/apiUtils';
import { saveAs } from 'services/utils';

const CapiSettingApiFactory = () => {
  const endpoint = `${pages[CAPI_SETTING].endpoint}`;
  const endpointUser = pages[USER_MANAGEMENT].endpoint;

  // Handle async for Capi Setting List because of sync timeout
  const fetchData = async (sort) => {
    const query = { sort };
    const asyncUrl = apiUtils.buildUrlWithQueryStrings(
      `${endpoint}-async`,
      query
    );

    return SettingsApi.requestAsync(asyncUrl, null, 'GET', {
      pollTokenKey: 'token',
      pollUrlFormat: `${endpoint}/polling/{token}`,
    });
  };

  // Handle async for Capi History because of sync timeout
  const fetchHistory = async () => {
    const asyncUrl = `${endpoint}/history-async`;

    return SettingsApi.requestAsync(asyncUrl, null, 'GET', {
      pollTokenKey: 'token',
      pollUrlFormat: `${endpoint}/polling/{token}`,
    });
  };

  const authenticationCheck = async (data) => {
    const { pixelId, accessToken } = data;
    const dataRequest = {};
    const url = `${pixelId}/events?access_token=${accessToken}`;

    return FbCApi.post(url, dataRequest);
  };

  const ggAuthenticationCheck = async (data) => {
    // Google Authen
    return SettingsApi.post(`${endpoint}/authenticate`, data);
  };

  const getYahooAccounts = async (data) => {
    const url = USER_ENDPOINT;
    const {
      yahooAccountId,
      yahooBaseAccountId,
      refreshToken,
      clientId,
      mediumId,
    } = data;
    return SettingsApi.get(
      apiUtils.buildUrlWithQueryStrings(`${url}/yahoo/accounts`, {
        yahoo_account_id: yahooAccountId,
        x_z_base_account_id: yahooBaseAccountId,
        refresh_token: refreshToken,
        client_id: clientId,
        medium_id: mediumId,
      })
    );
  };

  const getGoogleAccounts = async (data) => {
    const url = USER_ENDPOINT;
    const { refreshToken, clientId } = data;
    return SettingsApi.get(
      apiUtils.buildUrlWithQueryStrings(`${url}/google/accounts`, {
        refresh_token: refreshToken,
        client_id: clientId,
      })
    );
  };

  const downloadCsvPostback = async () => {
    const urlPostbackCsv = `${endpoint}/history/export/csv`;
    const response = await SettingsApi.requestAsync(
      urlPostbackCsv,
      null,
      'POST',
      {
        pollTokenKey: 'task',
        pollUrlFormat: `${urlPostbackCsv}/{task}`,
      }
    );
    const { location } = response.data.data;
    if (location) {
      saveAs(location);
    }
  };

  return {
    fetchData,
    changeStatus: async (id, status) =>
      SettingsApi.put(`${endpoint}/${id}/status`, {
        pause_flag: status,
      }),
    delete: async (ids) =>
      SettingsApi.delete(apiUtils.buildUrlWithQueryStrings(endpoint, { ids })),
    fetchHistory,
    authenticationCheck,
    ggAuthenticationCheck,
    lnAuthenticationCheck: async (data) =>
      SettingsApi.post(`${endpoint}/authenticate`, data),
    yahooAuthenticationCheck: async (data) =>
      SettingsApi.post(`${endpoint}/authenticate`, data),
    ttAuthenticationCheck: async (data) =>
      SettingsApi.post(`${endpoint}/authenticate`, data),
    xcAuthenticationCheck: async (data) =>
      SettingsApi.post(`${endpoint}/authenticate`, data),
    mfAuthenticationCheck: async (data) =>
      SettingsApi.post(`${endpoint}/authenticate`, data),
    create: async (data) => SettingsApi.post(endpoint, data),
    getDetail: async (id) => SettingsApi.get(`${endpoint}/${id}`),
    update: async (id, data) => SettingsApi.put(`${endpoint}/${id}`, data),
    fetchUsers: async () => Api.get(endpointUser),
    getYahooAccounts,
    getGoogleAccounts,
    downloadCsvPostback,
  };
};

const CapiSettingApi = CapiSettingApiFactory();
export default CapiSettingApi;
