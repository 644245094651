/**
 * Based on AUA 2020 Phase 1 docs
 * Updated date: 2020/12/10
 * Ref: https://docs.google.com/spreadsheets/d/1J0uwatOF7vKAB4K0WUCRV5_SCNQQSrsl/edit#gid=1196540595
 */

/**
 * User permissions definitions
 */
// roles view_ad
export const AD_ANALYSIS_ITEM_LOG_VIEW = 'ad_analysis/item_log:view';
export const AD_ANALYSIS_ITEM_EXCLUDE_LOG_VIEW =
  'ad_analysis/item_exclude_log:view';
export const AD_DASHBOARD_VIEW = 'ad_dashboard:view';
export const AD_ANALYSIS_LPO_VIEW = 'ad_analysis/lpo:view';
export const AD_ANALYSIS_COST_ALLOCATION_VIEW =
  'ad_analysis/cost_allocation:view';
// roles view_all
export const ALL_DASHBOARD_CHNL_CLK_SRCH_VIEW =
  'all_dashboard/chnl_clk_srch:view';
export const ALL_DASHBOARD_CHNL_CLK_SRCH_PV_VIEW =
  'all_dashboard/chnl_clk_srch_pv:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_LOG_VIEW =
  'all_analysis/chnl_clk_srch/item_log:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW =
  'all_analysis/chnl_clk_srch/item_exclude_log:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_PV_VIEW =
  'all_analysis/chnl_clk_srch_pv:view';
export const ALL_LOG_VIEW = 'all_log:view';
// permission for SESSIONLOG
export const ALL_SESSION_LOG_VIEW =
  'all_analysis/chnl_clk_id/item_session_log:view';
export const ALL_USER_ANALYSIS_USER_PF_VIEW = 'all_user_analysis_user_pf:view';
// roles view_all_ad_only
export const ALL_DASHBOARD_CHNL_CLK_SRCH_ITEM_AD_VIEW =
  'all_dashboard/chnl_clk_srch/item_ad:view';
export const ALL_DASHBOARD_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW =
  'all_dashboard/chnl_clk_srch_pv/item_ad:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_ITEM_AD_VIEW =
  'all_analysis/chnl_clk_srch/item_ad:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_PV_ITEM_AD_VIEW =
  'all_analysis/chnl_clk_srch_pv/item_ad:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_PV_SC_VIEW =
  'all_analysis/chnl_clk_srch_pv_sc:view';

export const DATA_EXPORT_VIEW = 'data_export:view';
export const DATA_EXPORT_AD_REPO_VIEW = 'data_export/adrepo:view';
export const DATA_EXPORT_EDIT = 'data_export:edit';
export const DATA_EXPORT_AD_REPO_EDIT = 'data_export/adrepo:edit';
export const DATA_EXPORT_CHNL_VIEW = 'data_export/chnl_view:view';
export const SETTING_GOAL_EDIT = 'setting/goal:edit';
export const SETTING_AD_AD_MANAGE_EDIT = 'setting/ad/ad_manage:edit';
export const SETTING_AD_DELIVERY_EDIT = 'setting/ad/delivery:edit';
export const SETTING_AD_MEASUREMENT_EDIT = 'setting/ad/measurement:edit';
export const SETTING_AD_MEDIA_EDIT = 'setting/ad/media:edit';
export const SETTING_AD_AD_GROUP1_EDIT = 'setting/ad/ad_group1:edit';
export const SETTING_AD_AD_GROUP2_EDIT = 'setting/ad/ad_group2:edit';

// Listing link
export const AD_LISTING_GA_VIEW = 'ad/listing/ga:view';
export const AD_LISTING_YD_VIEW = 'ad/listing/yd:view';
export const AD_LISTING_YH_VIEW = 'ad/listing/yh:view';

// Cost link
export const COST_VIEW_MEDIA_LINK_VIEW = 'cost_view/media_link:view';
export const SETTING_COST_VIEW_AD_EDIT = 'setting/cost_view/ad:edit';
export const SETTING_COST_VIEW_MEDIA_LINK_EDIT =
  'setting/cost_view/media_link:edit';

export const SETTING_TAG_TAG_MANAGER_EDIT = 'setting/tag/tag_manager:edit';
export const SETTING_TAG_CONTENT_CATEGORY_EDIT =
  'setting/tag/content_category:edit';
export const SETTING_TAG_PV_EDIT = 'setting/tag/content_category:edit';
export const SETTING_TAG_ONE_TAG_EDIT = 'setting/tag/one_tag:edit';
export const SETTING_SYSTEM_EDIT = 'setting/system:edit';
export const SETTING_DNS_EDIT = 'setting/cname:edit';
export const SETTING_USER_EDIT = 'setting/user:edit';
export const SETTING_EXTERNAL_LINKS_EDIT = 'setting/external_links:edit';
export const ACCOUNT_USAGE_VIEW = 'account/usage_traffic:view';
export const ACCOUNT_USAGE_LOG_VIEW = 'account/usage_log:view';
export const ACCOUNT_BILLING_CONTRACT_VIEW = 'account/billing_contract:view';
export const SETTING_AGENCY_VIEW = 'setting/agency:view';
export const SETTING_AGENCY_EDIT = 'setting/agency:edit';
// New API
export const SETTING_EBIS_API_VIEW = 'setting/ebis_api:view';
export const SETTING_EBIS_API_EDIT = 'setting/ebis_api:edit';

// LTV permissions
export const ALL_ANALYSIS_CHNL_CLK_SRCH_LTV_VIEW =
  'all_analysis/ltv/chnl_clk_srch:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_LTV_AD_ONLY_VIEW =
  'all_analysis/ltv/chnl_clk_srch/ad_only:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_PV_LTV_VIEW =
  'all_analysis/ltv/chnl_clk_srch_pv:view';
export const ALL_ANALYSIS_CHNL_CLK_SRCH_PV_LTV_AD_ONLY_VIEW =
  'all_analysis/ltv/chnl_clk_srch_pv/ad_only:view';
export const AD_ANALYSIS_LTV_VIEW = 'ad_analysis/ltv:view';
export const MANAGE_LTV_EDIT = 'manage/ltv:edit';
export const SETTING_LTV_AD_LINKING_EDIT = 'setting/ltv/ad_linking:edit';
export const SETTING_LTV_BASE_EDIT = 'setting/ltv/base:edit';
// Media Postback setting permissions
export const SETTING_AD_POSTBACK_EDIT = 'setting/ad/postback:edit';
// "Sync" PJ permissions
export const MEDIA_SYNC_VIEW = 'media_sync:view';
export const MEDIA_SYNC_MANAGEMENT_VIEW = 'media_sync_management:view';
export const MEDIA_SYNC_MANAGEMENT_EDIT = 'media_sync_management:edit';
// User Profile Permission
export const ALL_ANALYSIS_USER_PF_CHNL_CLK_SRCH_PV_ITEM_LOG_VIEW =
  'all_analysis/user_pf/chnl_clk_srch_pv/item_log:view';
export const ALL_ANALYSIS_USER_PF_CHNL_CLK_SRCH_ITEM_EXCLUDE_LOG_VIEW =
  'all_analysis/user_pf/chnl_clk_srch/item_exclude_log:view';

// system setting
export const SYSTEM_SETTING_BASIC = 'setting/system_basic:edit';
export const SYSTEM_SETTING_MEASUREMENT_OPTION = 'setting/system_option:edit';
export const SYSTEM_SETTING_DISPLAY = 'setting/system_display:edit';
export const SYSTEM_SETTING_CROSS_DEVICE = 'setting/system_cross:edit';

// page management
export const SETTING_PAGE_MANAGEMENT_EDIT = 'setting/tag/page_manager:edit';

// Cross Device permission
export const CROSS_DEVICE_VIEW = 'cross_device:view';

// capi setting
export const CAPI_SETTING_VIEW = 'setting/capi:view';
export const CAPI_SETTING_EDIT = 'setting/capi:edit';

// search console setting
export const SC_SETTING_VIEW = 'setting/sc:view';
export const SC_SETTING_EDIT = 'setting/sc:edit';

// line setting
export const LINE_SETTING_VIEW = 'setting/line_add_friend:view';
export const LINE_SETTING_EDIT = 'setting/line_add_friend:edit';

// capi single permission
export const CAPI_SINGLE_LIMIT = 'account/capi_single:limit';

// data export paid
export const DATA_EXPORT_PAID = 'data_export_paid:edit';
