import React from 'react';
import {
  CAPI_SETTING_NAME,
  PAUSE_FLAG,
  MEDIUM_ID,
  UPDATE_BY,
  CV_POINTS,
  EXECUTED_DATE,
  REGIST_DATE,
  UPDATE_DATE,
  PIXEL_ID,
  ACCESS_TOKEN,
  CUSTOMER_ID,
  LOGIN_CUSTOMER_ID,
  REFRESH_TOKEN,
  AUTHEN_WITH_MCC_ACCOUNT,
  LINE_TAG_ID,
  LINE_TAG_ACCESS_TOKEN,
  YAHOO_ACCOUNT_ID,
  YAHOO_YDN_CONV_IO,
  YAHOO_BASE_ACCOUNT_ID,
  PIXEL_CODE,
  X_PIXEL_ID,
  OAUTH_TOKEN,
  OAUTH_TOKEN_SECRET,
  MICROSOFT_CUSTOMER_ID,
  MICROSOFT_CUSTOMER_ACCOUNT_ID,
  EMAIL_NOTIFICATION,
  SEND_WITH_PRIVATE_INFO,
  STATUS,
  SEND_MAIL_USERS,
  USER_ID,
  COMPANY_NAME,
  CHARGE_NAME,
  EMAIL,
} from 'domain/fields';
import * as LABELS from 'domain/labels';
import iconStop from 'assets/images/icon-stop.svg';
import iconStart from 'assets/images/icon-started.svg';

export const MAX_CREATE_CAPI_SETTING = 50;
export const LIST_STATUS_KEY = 'list';
export const HISTORY_STATUS_KEY = 'history';
export const SETTING_STATUS_KEY = 'setting';
export const MASTERDATA_STATUS_KEY = 'masterData';

export const AUTH_ERROR = 'auth_error';
export const REQUIRE_FIELD_ERROR = 'require_field_error';
export const OVER_LIMIT_CV_SENT = 'over_limit_cv_sent';

export const AUTHEN_FLAG = 'authen_flag';

const FACEBOOK_CONVERSION_LABEL = 'Facebook コンバージョンAPI';
const GOOGLE_CONVERSION_LABEL = 'Google 拡張コンバージョン';
const GOOGLE_OFFLINE_CONVERSION_LABEL = 'Google コンバージョンインポート';
const LINE_CONVERSION_LABEL = 'LINE Conversion API';
const YAHOO_CONVERSION_API_LABEL = 'Yahoo!ディスプレイ広告 コンバージョンAPI';
const YAHOO_DISPLAY_AD_CONVERSION_LABEL =
  'Yahoo!ディスプレイ広告 コンバージョンインポート';
const YAHOO_SEARCH_AD_CONVERSION_LABEL =
  'Yahoo!検索広告 コンバージョンインポート';
const TIKTOK_CONVERSION_LABEL = 'TikTok Events API';
const X_CONVERSION_LABEL = 'X コンバージョンAPI';
const MICROSOFT_CONVERSION_LABEL = 'Microsoft コンバージョンインポート';

export const HEADER = [
  {
    field: 'rowId',
    fieldResponse: 'id',
    name: '',
    type: 'checkbox',
    isFreeze: true,
    isEdit: true,
  },
  {
    field: PAUSE_FLAG,
    name: LABELS.PAUSE_FLAG,
    isFreeze: true,
    sort: 'none',
  },
  {
    field: STATUS,
    name: LABELS.CAPI_STATUS,
    isFreeze: true,
  },
  {
    field: CAPI_SETTING_NAME,
    name: LABELS.CAPI_SETTING_NAME,
    isFreeze: true,
  },
  {
    field: MEDIUM_ID,
    name: LABELS.MEDIA_DESTINATION,
  },
  {
    field: UPDATE_BY,
    name: LABELS.CAPI_USER_ID,
  },
  {
    field: CV_POINTS,
    name: LABELS.CV_TITLE,
    type: 'inlineTruncate',
    sort: 'none',
  },
  {
    field: EXECUTED_DATE,
    name: LABELS.EXECUTED_DATE,
  },
  {
    field: REGIST_DATE,
    name: LABELS.REGIST_DATE,
  },
  {
    field: UPDATE_DATE,
    name: LABELS.UPDATE_DATE,
  },
];

export const MODAL_IDS = {
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  ERROR: 'error',
  HISTORY: 'history',
};

export const HISTORY_TABS = {
  SUMMARY: 'summary',
  POST_HISTORY: 'post_history',
};

export const TABS = [
  {
    key: HISTORY_TABS.SUMMARY,
    label: 'CV送信数',
  },
  {
    key: HISTORY_TABS.POST_HISTORY,
    label: '送信履歴一覧',
  },
];

export const HEADER_HISTORY = [
  {
    field: 'executed_date',
    name: '実行日時',
    width: '175px',
  },
  {
    field: 'cv_time',
    name: 'CV発生日時',
    width: '175px',
  },
  {
    field: 'capi_name',
    name: '設定名',
    type: 'inlineTruncate',
  },
  {
    field: 'medium_id',
    name: '連携先',
    type: 'inlineTruncate',
  },
  {
    field: 'cv_name',
    name: 'CV名',
    type: 'inlineTruncate',
  },
  {
    field: 'http_status_code',
    name: '結果',
  },
];

export const TOOLTIP_MESSAGES_HISTORY_TABLE = {
  executed_date: '送信月は前月21日から当月20日までで集計されます',
};

// CAPI Setting
export const STEP_SETTING_MEDIA = 1;
export const STEP_SETTING_CV_POINTS = 2;
export const STEP_CONFIRM = 3;
export const STEPS_CAPI_SETTING_FORM = [
  {
    step: STEP_SETTING_MEDIA,
    label: '媒体情報',
    active: false,
  },
  {
    step: STEP_SETTING_CV_POINTS,
    label: 'CVポイント',
    active: false,
  },
  {
    step: STEP_CONFIRM,
    label: '確認',
    active: false,
  },
];

// CAPI Setting fields
export const CAPI_SETTING_ID = 'id';

export const CAPI_NAME_MAX_LENGTH = 100;

export const EVENT_NAME_MAX_LENGTH = 255;
export const YAHOO_OFF_CONV_NAME_MAX_LENGTH = 100;
export const YAHOO_YDN_CONV_LABEL_MAX_LENGTH = 32;

/**
 * Medium IDs
 * fb: Facebook Conversion API
 */
export const FACEBOOK = 'fb';
export const LINE = 'ln';
const YAHOO = 'yd';
export const GOOGLE = 'gg';
export const GOOGLE_OFFLINE = 'gf';
export const YAHOO_CONVERSION_API = 'yd';
export const YAHOO_DISPLAY_AD = 'yf';
export const YAHOO_SEARCH_AD = 'ys';
export const TIKTOK = 'tt';
export const X_CONVERSION_API = 'xc';
export const MICROSOFT = 'mf';

export const LIST_MEDIUM_IDS = {
  [FACEBOOK]: {
    key: FACEBOOK,
    name: FACEBOOK_CONVERSION_LABEL,
  },
  [GOOGLE]: {
    key: GOOGLE,
    name: GOOGLE_CONVERSION_LABEL,
  },
  [GOOGLE_OFFLINE]: {
    key: GOOGLE_OFFLINE,
    name: GOOGLE_OFFLINE_CONVERSION_LABEL,
  },
  [LINE]: {
    key: LINE,
    name: LINE_CONVERSION_LABEL,
  },
  [MICROSOFT]: {
    key: MICROSOFT,
    name: MICROSOFT_CONVERSION_LABEL,
  },
  [X_CONVERSION_API]: {
    key: X_CONVERSION_API,
    name: X_CONVERSION_LABEL,
  },
  [YAHOO_CONVERSION_API]: {
    key: YAHOO_CONVERSION_API,
    name: YAHOO_CONVERSION_API_LABEL,
  },
  [YAHOO_DISPLAY_AD]: {
    key: YAHOO_DISPLAY_AD,
    name: YAHOO_DISPLAY_AD_CONVERSION_LABEL,
  },
  [YAHOO_SEARCH_AD]: {
    key: YAHOO_SEARCH_AD,
    name: YAHOO_SEARCH_AD_CONVERSION_LABEL,
  },
  [TIKTOK]: {
    key: TIKTOK,
    name: TIKTOK_CONVERSION_LABEL,
  },
};

export const MEDIUM_TITLE = {
  [FACEBOOK]: FACEBOOK_CONVERSION_LABEL,
  [GOOGLE]: GOOGLE_CONVERSION_LABEL,
  [GOOGLE_OFFLINE]: GOOGLE_OFFLINE_CONVERSION_LABEL,
  [LINE]: LINE_CONVERSION_LABEL,
  [YAHOO_CONVERSION_API]: YAHOO_CONVERSION_API_LABEL,
  [YAHOO_DISPLAY_AD]: YAHOO_DISPLAY_AD_CONVERSION_LABEL,
  [YAHOO_SEARCH_AD]: YAHOO_SEARCH_AD_CONVERSION_LABEL,
  [TIKTOK]: TIKTOK_CONVERSION_LABEL,
  [X_CONVERSION_API]: X_CONVERSION_LABEL,
  [MICROSOFT]: MICROSOFT_CONVERSION_LABEL,
};

const TOTAL_KEY = 'total_cv';

export const MEDIUM = {
  [FACEBOOK]: {
    field: `${TOTAL_KEY}_${FACEBOOK}`,
    name: `Facebook\nコンバージョンAPI`,
    width: '150px',
    minWidth: '150px',
    sort: 'none',
  },
  [GOOGLE]: {
    field: `${TOTAL_KEY}_${GOOGLE}`,
    name: `Google\n拡張コンバージョン`,
    width: '150px',
    minWidth: '150px',
    sort: 'none',
  },
  [GOOGLE_OFFLINE]: {
    field: `${TOTAL_KEY}_${GOOGLE_OFFLINE}`,
    name: `Google\nコンバージョンインポート`,
    width: '189px',
    minWidth: '189px',
    sort: 'none',
  },
  [LINE]: {
    field: `${TOTAL_KEY}_${LINE}`,
    name: `Line\nConversion API`,
    width: '150px',
    minWidth: '150px',
    sort: 'none',
  },
  [MICROSOFT]: {
    field: `${TOTAL_KEY}_${MICROSOFT}`,
    name: `Microsoft\nコンバージョンインポート`,
    width: '189px',
    minWidth: '189px',
    sort: 'none',
  },
  [X_CONVERSION_API]: {
    field: `${TOTAL_KEY}_${X_CONVERSION_API}`,
    name: `X コンバージョン\nAPI`,
    width: '150px',
    minWidth: '150px',
    sort: 'none',
  },
  [YAHOO_CONVERSION_API]: {
    field: `${TOTAL_KEY}_${YAHOO_CONVERSION_API}`,
    name: `Yahoo!ディスプレイ広告\nコンバージョンAPI`,
    width: '187px',
    minWidth: '187px',
    sort: 'none',
  },
  [YAHOO_DISPLAY_AD]: {
    field: `${TOTAL_KEY}_${YAHOO_DISPLAY_AD}`,
    name: `Yahoo!ディスプレイ広告\nコンバージョンインポート`,
    width: '189px',
    minWidth: '189px',
    sort: 'none',
  },
  [YAHOO_SEARCH_AD]: {
    field: `${TOTAL_KEY}_${YAHOO_SEARCH_AD}`,
    name: `Yahoo!検索広告\nコンバージョンインポート`,
    width: '189px',
    minWidth: '189px',
    sort: 'none',
  },
  [TIKTOK]: {
    field: `${TOTAL_KEY}_${TIKTOK}`,
    name: `TikTok\nEvents API`,
    width: '150px',
    minWidth: '150px',
    sort: 'none',
  },
};

export const HEADER_CV_HISTORY = Object.keys(MEDIUM);

export const FACEBOOK_SUPPORT_SITE =
  'https://support.ebis.ne.jp/s/article/000004893';
export const GOOGLE_SUPPORT_SITE =
  'https://support.ebis.ne.jp/s/article/000004894';
export const LINE_SUPPORT_SITE =
  'https://support.ebis.ne.jp/s/article/000004895';

export const YAHOO_CONV_API_SUPPORT_SITE =
  'https://support.ebis.ne.jp/s/article/000004914';
export const YAHOO_DISPLAY_AD_SUPPORT_SITE =
  'https://support.ebis.ne.jp/s/article/000004915';
export const YAHOO_SEARCH_AD_SUPPORT_SITE =
  'https://support.ebis.ne.jp/s/article/000004916';
const TIKTOK_SUPPORT_SITE = 'https://support.ebis.ne.jp/s/article/000004920';
export const X_SUPPORT_SITE = 'https://support.ebis.ne.jp/s/article/000004950';
export const MICROSOFT_SUPPORT_SITE =
  'https://support.ebis.ne.jp/s/article/000004951';

export const PRIVATE_INFO_SUPPORT_SITE =
  'https://support.ebis.ne.jp/s/article/000004886#004-2';

const LABELS_MEDIA = {
  [FACEBOOK]: {
    [PIXEL_ID]: 'pixel_id',
    [ACCESS_TOKEN]: 'access_token',
  },
  [GOOGLE]: {
    [CUSTOMER_ID]: 'customerId',
    [LOGIN_CUSTOMER_ID]: 'login_customer_id',
  },
  [GOOGLE_OFFLINE]: {
    [CUSTOMER_ID]: 'customerId',
    [LOGIN_CUSTOMER_ID]: 'login_customer_id',
  },
  [LINE]: {
    [LINE_TAG_ID]: 'line_tag_id',
    [LINE_TAG_ACCESS_TOKEN]: 'X-Line-TagAccessToken',
  },
  [YAHOO_CONVERSION_API]: {
    [YAHOO_YDN_CONV_IO]: 'yahoo_ydn_conv_io',
  },
  [YAHOO_DISPLAY_AD]: {
    [YAHOO_ACCOUNT_ID]: 'account_id',
  },
  [YAHOO_SEARCH_AD]: {
    [YAHOO_ACCOUNT_ID]: 'account_id',
  },
  [TIKTOK]: {
    [PIXEL_CODE]: 'ピクセルID',
    [ACCESS_TOKEN]: 'アクセストークン',
  },
  [X_CONVERSION_API]: {
    [X_PIXEL_ID]: 'pixel_id',
  },
  [MICROSOFT]: {
    [MICROSOFT_CUSTOMER_ID]: 'CustomerId',
    [MICROSOFT_CUSTOMER_ACCOUNT_ID]: 'CustomerAccountId',
  },
};

const getMessageByMedia = (media = '', fieldRequest = '') => {
  return `${media}広告の連携には${fieldRequest}が必要です。詳しくは`;
};

const getMessageByGoogleOfflineMedia = (media = '') => {
  return `${media}の連携にはcustomerIdと developerTokenが必要です。詳しくは`;
};

const getMessageLoginCustomerId = (fieldRequest = '') => {
  return `認証を行ったMCCアカウントの${fieldRequest}を選択してください。詳しくは`;
};

const getTooltip = (message = '', link = '') => {
  return (
    <>
      {message}
      {link && (
        <a href={link} target="_blank" rel="noopener noreferrer">
          こちら
        </a>
      )}
    </>
  );
};

const FACEBOOK_TOOLTIP = getTooltip(
  getMessageByMedia('Facebook', 'pixel_idとaccess_token'),
  FACEBOOK_SUPPORT_SITE
);

const GOOGLE_CUSTOMER_TOOLTIP = getTooltip(
  getMessageByMedia('Google', 'customerId'),
  GOOGLE_SUPPORT_SITE
);

const GOOGLE_LOGIN_CUSTOMER_ID_TOOLTIP = getTooltip(
  getMessageLoginCustomerId('customerId'),
  GOOGLE_SUPPORT_SITE
);

const GF_CUSTOMER_ID_TOOLTIP = getTooltip(
  getMessageByGoogleOfflineMedia('Google コンバージョンインポート'),
  GOOGLE_SUPPORT_SITE
);

const LINE_TOOLTIP = getTooltip(
  getMessageByMedia('LINE', 'line_tag_id、 X-Line-TagAccessToken'),
  LINE_SUPPORT_SITE
);

const YAHOO_CONV_API_TOOLTIP = getTooltip(
  'Yahoo!ディスプレイ広告 コンバージョンAPIの連携には yahoo_ydn_conv_ioが必要です。詳しくは',
  YAHOO_CONV_API_SUPPORT_SITE
);

const TIKTOK_TOOLTIP = getTooltip(
  getMessageByMedia('TikTok', 'ピクセルIDとアクセストークン'),
  TIKTOK_SUPPORT_SITE
);

const X_TOOLTIP = getTooltip(
  'X コンバージョンAPIの連携にはpixel_idが必要です。詳しくは',
  X_SUPPORT_SITE
);

const MICROSOFT_TOOLTIP = getTooltip(
  'Microsoft コンバージョンインポートの連携にはCustomerIdとCustomerAccountIdが必要です。詳しくは',
  MICROSOFT_SUPPORT_SITE
);

const DEFAULT_PLACE_HOLDER = '値を入力';
export const DEFAULT_YAHOO_ACCOUNT_ID = '0';
export const DEFAULT_SELECT_YAHOO_ACCOUNT_ID = {
  key: 0,
  name: '選択してください',
  id: '0',
};
export const DEFAULT_LOGIN_CUSTOMER_ID = '0';
export const CREDENTIAL_OBJ = {
  [FACEBOOK]: {
    [PIXEL_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[FACEBOOK][PIXEL_ID],
      value: '',
      tooltip: FACEBOOK_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    [ACCESS_TOKEN]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[FACEBOOK][ACCESS_TOKEN],
      value: '',
      tooltip: FACEBOOK_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
      type: 'password',
    },
  },
  [GOOGLE]: {
    [AUTHEN_WITH_MCC_ACCOUNT]: {
      step: STEP_SETTING_MEDIA,
      label: '認証方式',
      checkboxText: 'MCCアカウントで認証する',
      note: '※MCCアカウントで認証する場合のみチェックを入れてください',
      value: false,
    },
    [CUSTOMER_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[GOOGLE][CUSTOMER_ID],
      value: '',
      tooltip: GOOGLE_CUSTOMER_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    [LOGIN_CUSTOMER_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[GOOGLE][LOGIN_CUSTOMER_ID],
      value: DEFAULT_LOGIN_CUSTOMER_ID,
      tooltip: GOOGLE_LOGIN_CUSTOMER_ID_TOOLTIP,
    },
    [REFRESH_TOKEN]: {
      step: STEP_SETTING_MEDIA,
      value: '',
    },
  },
  [GOOGLE_OFFLINE]: {
    [AUTHEN_WITH_MCC_ACCOUNT]: {
      step: STEP_SETTING_MEDIA,
      label: '認証方式',
      checkboxText: 'MCCアカウントで認証する',
      note: '※MCCアカウントで認証する場合のみチェックを入れてください',
      value: false,
    },
    [CUSTOMER_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[GOOGLE_OFFLINE][CUSTOMER_ID],
      value: '',
      tooltip: GF_CUSTOMER_ID_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    [LOGIN_CUSTOMER_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[GOOGLE][LOGIN_CUSTOMER_ID],
      value: DEFAULT_LOGIN_CUSTOMER_ID,
      tooltip: GOOGLE_LOGIN_CUSTOMER_ID_TOOLTIP,
    },
    [REFRESH_TOKEN]: {
      step: STEP_SETTING_MEDIA,
      value: '',
    },
  },
  [LINE]: {
    [LINE_TAG_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[LINE][LINE_TAG_ID],
      value: '',
      tooltip: LINE_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    [LINE_TAG_ACCESS_TOKEN]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[LINE][LINE_TAG_ACCESS_TOKEN],
      value: '',
      tooltip: LINE_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
      type: 'password',
    },
  },
  [YAHOO_CONVERSION_API]: {
    [YAHOO_YDN_CONV_IO]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[YAHOO_CONVERSION_API][YAHOO_YDN_CONV_IO],
      tooltip: YAHOO_CONV_API_TOOLTIP,
      value: '',
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
      type: 'password',
    },
  },
  [YAHOO_DISPLAY_AD]: {
    [YAHOO_ACCOUNT_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[YAHOO_DISPLAY_AD][YAHOO_ACCOUNT_ID],
      value: DEFAULT_YAHOO_ACCOUNT_ID,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    [REFRESH_TOKEN]: {
      step: STEP_SETTING_MEDIA,
      value: '',
    },
    [YAHOO_BASE_ACCOUNT_ID]: {
      step: STEP_SETTING_MEDIA,
      value: '',
    },
  },
  [YAHOO_SEARCH_AD]: {
    [YAHOO_ACCOUNT_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[YAHOO_SEARCH_AD][YAHOO_ACCOUNT_ID],
      value: DEFAULT_YAHOO_ACCOUNT_ID,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    [REFRESH_TOKEN]: {
      step: STEP_SETTING_MEDIA,
      value: '',
    },
    [YAHOO_BASE_ACCOUNT_ID]: {
      step: STEP_SETTING_MEDIA,
      value: '',
    },
  },
  [TIKTOK]: {
    [PIXEL_CODE]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[TIKTOK][PIXEL_CODE],
      value: '',
      tooltip: TIKTOK_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    [ACCESS_TOKEN]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[TIKTOK][ACCESS_TOKEN],
      value: '',
      tooltip: TIKTOK_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
      type: 'password',
    },
  },
  [X_CONVERSION_API]: {
    [X_PIXEL_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[X_CONVERSION_API][X_PIXEL_ID],
      value: '',
      tooltip: X_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    // Not display (Will not be displayed unless specified in getCredentialsByMediumId)
    [OAUTH_TOKEN]: {
      step: STEP_SETTING_MEDIA,
    },
    [OAUTH_TOKEN_SECRET]: {
      step: STEP_SETTING_MEDIA,
    },
  },
  [MICROSOFT]: {
    [MICROSOFT_CUSTOMER_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[MICROSOFT][MICROSOFT_CUSTOMER_ID],
      value: '',
      tooltip: MICROSOFT_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    [MICROSOFT_CUSTOMER_ACCOUNT_ID]: {
      step: STEP_SETTING_MEDIA,
      label: LABELS_MEDIA[MICROSOFT][MICROSOFT_CUSTOMER_ACCOUNT_ID],
      value: '',
      tooltip: MICROSOFT_TOOLTIP,
      placeholder: DEFAULT_PLACE_HOLDER,
      required: true,
    },
    // Not display (Will not be displayed unless specified in getCredentialsByMediumId)
    [REFRESH_TOKEN]: {
      step: STEP_SETTING_MEDIA,
    },
  },
};

export const FIELD_CAPI_SETTING_FORM = {
  [CAPI_SETTING_NAME]: {
    step: STEP_SETTING_MEDIA,
    label: '設定名',
    value: '',
    placeholder: '値を入力',
    note: '100文字以内',
  },
  [MEDIUM_ID]: {
    step: STEP_SETTING_MEDIA,
    label: '連携先',
    value: FACEBOOK,
  },
  [EMAIL_NOTIFICATION]: {
    step: STEP_SETTING_MEDIA,
    label: '認証エラー発生時のメール通知',
    value: true,
  },
  [SEND_WITH_PRIVATE_INFO]: {
    step: STEP_SETTING_MEDIA,
    label: '個人情報の取得',
    checkboxText: '取得する',
    tooltip: getTooltip(
      '媒体側から取得した個人情報のデータを、アドエビス側のデータと紐づけることでより精度の高い広告配信の最適化が期待できます。媒体によって取得必須となります。詳しくは',
      PRIVATE_INFO_SUPPORT_SITE
    ),
    value: false,
  },
  [SEND_MAIL_USERS]: {
    step: STEP_SETTING_MEDIA,
    label: '送信先',
    value: [],
  },
  [CV_POINTS]: {
    step: STEP_SETTING_CV_POINTS,
    label: 'CVポイント対応設定',
    value: {},
  },
};

const AGENT_FIELD = 'agent';
export const HEADER_SEND_MAIL_USERS = [
  {
    isFreeze: true,
    field: 'rowId',
    name: '',
    width: '45px',
    type: 'checkbox',
  },
  {
    isFreeze: true,
    field: USER_ID,
    name: 'ユーザーID',
    width: 'max-content',
    minWidth: '150px',
  },
  {
    field: COMPANY_NAME,
    name: '会社名',
    width: '201px',
  },
  {
    field: CHARGE_NAME,
    name: '名前',
    width: '121px',
  },
  {
    field: EMAIL,
    name: 'メールアドレス',
    width: 'max-content',
    minWidth: '231px',
  },
  {
    field: AGENT_FIELD,
    name: 'エージェント',
    width: '109px',
    variant: 'icon-check',
  },
];

export const HEADER_SEND_MAIL_USERS_SINGLE = HEADER_SEND_MAIL_USERS.filter(
  (item) => item.field !== AGENT_FIELD
);

const FACEBOOK_CV_POINT_TOOLTIP_MESSAGE =
  'Metaピクセルに設定されているeventを入力してください。詳しくは';
const GOOGLE_CV_POINT_TOOLTIP_MESSAGE =
  'Google広告の管理画面にて確認可能なコンバージョンアクションIDを入力してください。詳しくは';
const LINE_CV_POINT_TOOLTIP_MESSAGE =
  'デフォルトコンバージョンは「Conversions」と入力してください。カスタムコンバージョンの場合は、管理画面にて確認可能な「カスタムイベント名」を入力してください。詳しくは';

const YAHOO_CV_POINT_TOOLTIP_MESSAGE =
  'コンバージョン測定（インポート）の新規設定時に入力した「コンバージョン名」を入力してください。詳しくは';
const YAHOO_CONV_API_CV_POINT_TOOLTIP_MESSAGE =
  '広告管理ツールで取得した「Conversion API リクエストパラメータ」の yahoo_conversion_labelの値を入力してください。詳しくは';
const TIKTOK_CV_POINT_TOOLTIP_MESSAGE =
  '標準イベントの場合はイベントコードを、カスタムイベントはTikTokイベントマネージャーで設定したカスタムイベントタイプを入力してください。詳しくは';
const X_CV_POINT_TOOLTIP_MESSAGE =
  'X広告の管理画面で設定したイベントIDを入力してください。詳しくは';
const MICROSOFT_CV_POINT_TOOLTIP_MESSAGE =
  'Microsoft広告の管理画面で、コンバージョン目標として設定した目標名を入力してください。詳しくは';

export const HEADER_CV_POINTS = {
  [FACEBOOK]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'Facebook（event_name）',
      width: '459px',
      tooltip: getTooltip(
        FACEBOOK_CV_POINT_TOOLTIP_MESSAGE,
        FACEBOOK_SUPPORT_SITE
      ),
    },
  ],
  [GOOGLE]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'Google（conversionActionId）',
      width: '459px',
      tooltip: getTooltip(GOOGLE_CV_POINT_TOOLTIP_MESSAGE, GOOGLE_SUPPORT_SITE),
    },
  ],
  [GOOGLE_OFFLINE]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'Google（conversionActionId）',
      width: '459px',
      tooltip: getTooltip(GOOGLE_CV_POINT_TOOLTIP_MESSAGE, GOOGLE_SUPPORT_SITE),
    },
  ],
  [LINE]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'LINE（event_name）',
      width: '459px',
      tooltip: getTooltip(LINE_CV_POINT_TOOLTIP_MESSAGE, LINE_SUPPORT_SITE),
    },
  ],
  [YAHOO_CONVERSION_API]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'Yahoo!ディスプレイ広告（yahoo_ydn_conv_label）',
      width: '459px',
      tooltip: getTooltip(
        YAHOO_CONV_API_CV_POINT_TOOLTIP_MESSAGE,
        YAHOO_CONV_API_SUPPORT_SITE
      ),
    },
  ],
  [YAHOO_DISPLAY_AD]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'コンバージョン名',
      width: '459px',
      tooltip: getTooltip(
        YAHOO_CV_POINT_TOOLTIP_MESSAGE,
        YAHOO_DISPLAY_AD_SUPPORT_SITE
      ),
    },
  ],
  [YAHOO_SEARCH_AD]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'コンバージョン名',
      width: '459px',
      tooltip: getTooltip(
        YAHOO_CV_POINT_TOOLTIP_MESSAGE,
        YAHOO_SEARCH_AD_SUPPORT_SITE
      ),
    },
  ],
  [TIKTOK]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'TikTok（event）',
      width: '459px',
      tooltip: getTooltip(TIKTOK_CV_POINT_TOOLTIP_MESSAGE, TIKTOK_SUPPORT_SITE),
    },
  ],
  [X_CONVERSION_API]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'X（event_id）',
      width: '459px',
      tooltip: getTooltip(X_CV_POINT_TOOLTIP_MESSAGE, X_SUPPORT_SITE),
    },
  ],
  [MICROSOFT]: [
    { name: 'conversionid', title: 'アドエビス（CV名）', width: '282px' },
    {
      name: 'value',
      title: 'Microsoft（目標名）',
      width: '459px',
      tooltip: getTooltip(
        MICROSOFT_CV_POINT_TOOLTIP_MESSAGE,
        MICROSOFT_SUPPORT_SITE
      ),
    },
  ],
};

// Accepted value of CAPI Setting fields
/**
 * Medium IDs
 * fb: Facebook Conversion API
 */
export const acceptedMediumIds = [
  FACEBOOK,
  GOOGLE,
  GOOGLE_OFFLINE,
  LINE,
  YAHOO_SEARCH_AD,
  YAHOO_DISPLAY_AD,
  YAHOO_CONVERSION_API,
  TIKTOK,
  X_CONVERSION_API,
  MICROSOFT,
];

/**
 * Email Notification
 */
export const acceptedEmailNotifications = [true, false];

/**
 * Table Messages
 */
export const MSG_NO_DATA = '表示するデータがありません';

/**
 * Define list params by medium id to show history detail in history tab
 */
export const HISTORY_DETAIL = {
  [FACEBOOK]: ['pixel_id', 'event_name', 'transaction_id', 'event_source_url'],
  [GOOGLE]: ['customerId', 'conversionActionId', 'transaction_id'],
  [GOOGLE_OFFLINE]: ['customerId', 'conversionActionId', 'transaction_id'],
  [LINE]: ['line_tag_id', 'url', 'transaction_id', 'event_name'],
  [YAHOO_CONVERSION_API]: [
    'yahoo_ydn_conv_io',
    'yahoo_ydn_conv_label',
    'yahoo_ydn_conv_value',
  ],
  [YAHOO_DISPLAY_AD]: ['accountId', 'cv_name'],
  [YAHOO_SEARCH_AD]: ['accountId', 'cv_name'],
  [TIKTOK]: ['pixel_code', 'event', 'transaction_id'],
  [X_CONVERSION_API]: ['pixel_id', 'event_id', 'transaction_id'],
  [MICROSOFT]: ['CustomerId', 'CustomerAccountId', 'ConversionName（目標名）'],
};

export const STATUS_TYPE_SUCCESS = 3;
export const STATUS_TYPE_FAILED = 4;
export const STATUS_TYPE_AUTH_EXPRIED = 6;
export const STATUS_TYPE_OVER_LIMIT = 0;

export const CAPI_STATUS_LABELS = {
  [STATUS_TYPE_FAILED]: 'エラー',
  [STATUS_TYPE_SUCCESS]: '成功',
  [STATUS_TYPE_AUTH_EXPRIED]: '認証エラー',
  [STATUS_TYPE_OVER_LIMIT]: '上限超過エラー',
};

export const ACTIVE_FLAG = 1;
export const BATCH_PAUSE_FLAG = 2;
export const USER_PAUSE_FLAG = 3;
export const OVER_LIMIT_FLAG = 4;

export const CAPI_PAUSE_FLAG = {
  [ACTIVE_FLAG]: {
    title: '連携',
    value: ACTIVE_FLAG,
    icon: iconStart,
  },
  [USER_PAUSE_FLAG]: {
    title: '一時停止',
    value: USER_PAUSE_FLAG,
    icon: iconStop,
  },
};

export const DELETE_CREDENTIAL_BY_MEDIUM = {
  [FACEBOOK]: [CUSTOMER_ID, LINE_TAG_ID, LINE_TAG_ACCESS_TOKEN],
  [GOOGLE]: [PIXEL_ID, ACCESS_TOKEN, LINE_TAG_ID, LINE_TAG_ACCESS_TOKEN],
  [LINE]: [PIXEL_ID, ACCESS_TOKEN, CUSTOMER_ID],
};

export const CLIENT_ID = 'client_id';

export const DEFAULT_ACCOUNT_IDS = {
  0: { key: 0, name: '選択してください' },
};

export const MAX_LIMIT_SEARCH = 501;
