export const PAGE_ID = 'ページID';
export const PAGE_NAME = 'ページタイトル';
export const PAGE_URL = 'ページURL';
export const PAGE_URLS = 'URL条件';
export const ROUTE_URLS = '経由ページ';
export const CV_TITLE = 'CV名';
export const CV_CONDITION = 'CV条件';
export const CV_URLS = 'CVページ';
export const CONTENT_CATEGORY = 'コンテンツカテゴリ';
export const RANK = '優先順位';
export const REGIST_DATE = '登録日時';
export const UPDATE_DATE = '最終更新日時';
export const TAG_MANAGER = 'タグマネージャーの利用';
export const OWNED_MEDIA = 'サイトコンテンツ';
export const URL = 'URL';
/**
/**
 * Begin of block fields CAPiCO設定
 * @type {string}
 */
export const PAUSE_FLAG = '送信ステータス';
export const CAPI_SETTING_NAME = '設定名';
export const CAPI_USER_ID = '設定ユーザーID';
export const MEDIA_DESTINATION = '連携先';
export const EXECUTED_DATE = '最終送信日時';
export const CAPI_STATUS = '最終送信結果';
/**
/**
 * End of block fields CAPiCO設定
 * @type {string}
 */

export const META_ADS = 'Meta広告（Facebook広告）';
export const GOOGLE_ADS = 'Google広告';
export const YAHOO_SEARCH_AD_LABEL = 'Yahoo!検索広告';
export const YAHOO_DISPLAY_AD_LABEL = 'Yahoo!ディスプレイ広告';
export const LINE_ADS = 'LINE広告';
export const TIKTOK_ADS = 'TikTok広告';
export const X_ADS = 'X広告';
export const MICROSOFT_ADS = 'Microsoft広告';
