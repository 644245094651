import * as field from 'domain/fields';
import * as DisplayItems from 'domain/settings/display-items';
import * as DisplayItemsAdManagement from 'domain/settings/display-items-ad-management';
import {
  CATEGORY_ANALYZE,
  COMPARE_PERIOD,
  LPO_PERIOD,
  LOG_PERIOD_ANALYZE,
  MEDIA_SYNC_MANAGEMENT,
} from 'services/routes/constants';
import { MARGIN_F1, MARGIN_F2, MARGIN_F3 } from 'domain/ltv/settings/constants';

const Metric = {
  media: {
    key: 'media_id',
    title: '媒体種別',
  },
  displayChannel: {
    key: 'display_channel',
    title: '表示チャネル',
  },
  displayChannelRange: {
    key: 'display_channel_range',
    title: '表示チャネル範囲',
  },
  summaryChannel: {
    key: 'channel_access_type',
    title: '集計チャネル',
  },
  summaryAllChannels: {
    key: 'channel_access_types',
    title: '集計チャネル',
  },
  targetCv: {
    key: 'conv_id',
    title: '対象コンバージョン',
  },
  userId: {
    key: 'tracking_data',
    title: 'ユーザーID',
  },
  userName: {
    key: 'member_name',
    title: 'ユーザー名',
  },
  adGroup1: {
    key: 'ad_group1_id',
    title: '広告グループ１',
  },
  adGroup2: {
    key: 'ad_group2_id',
    title: '広告グループ２',
  },
  mediaSideCampaign: {
    key: 'media_side_campaign_id',
    title: '媒体キャンペーン',
  },
  mediaSideGroup: {
    key: 'media_side_group_id',
    title: '媒体広告グループ',
  },
  mediaAccountName: {
    key: 'media_account_name',
    title: '媒体',
  },
  mediaSideAdName: {
    key: 'media_side_ad_name',
    title: '広告名',
  },
  device: {
    key: 'terminal_type',
    title: 'デバイス',
  },
  cross_device: {
    key: 'cross_device',
    title: 'クロスデバイス',
  },
  adName: {
    key: 'ad_name',
    title: '広告名',
  },
  adNote: {
    key: 'ad_note',
    title: '備考',
  },
  adId: {
    key: 'ad_id',
    title: '広告ID',
  },
  linkUrl: {
    key: 'link_url',
    title: 'リンク先URL',
  },
  contactTiming: {
    key: 'contact_history',
    title: '接触タイミング',
  },
  landingPage: {
    key: 'landing_page_url',
    title: 'URL（ランディングページ）',
  },
  other1: {
    key: 'other1',
    title: '項目１',
  },
  other2: {
    key: 'other2',
    title: '項目２',
  },
  other3: {
    key: 'other3',
    title: '項目３',
  },
  other4: {
    key: 'other4',
    title: '項目４',
  },
  other5: {
    key: 'other5',
    title: '項目５',
  },
  amount: {
    key: 'amount',
    title: '売上金額',
  },
  cntDirect: {
    key: 'cnt_direct',
    title: '直接効果',
  },
  cntIndirect2: {
    key: 'cnt_indirect2',
    title: '間接効果2',
  },
  cntIndirect3: {
    key: 'cnt_indirect3',
    title: '間接効果3',
  },
  cntIndirect4: {
    key: 'cnt_indirect4',
    title: '間接効果4',
  },
  cntIndirect5: {
    key: 'cnt_indirect5',
    title: '間接効果5',
  },
  cntIndirect6: {
    key: 'cnt_indirect6',
    title: '間接効果6',
  },
  cntIndirect7: {
    key: 'cnt_indirect7',
    title: '間接効果7',
  },
  cntIndirect8: {
    key: 'cnt_indirect8',
    title: '間接効果8',
  },
  cntIndirect9: {
    key: 'cnt_indirect9',
    title: '間接効果9',
  },
  cntIndirect10: {
    key: 'cnt_indirect10',
    title: '間接効果10',
  },
  cntFirst: {
    key: 'cnt_first',
    title: '初回接触',
  },
  searchWord: {
    key: 'search_word',
    title: '検索ワード',
  },
  ltvProductName: {
    key: 'product_name',
    title: '商品',
  },
  ltvOffertName: {
    key: 'offer_name',
    title: 'オファー',
  },
  link_page_title: {
    key: 'link_page_title',
    title: 'リンク先タイトル',
  },
  transition_rate: {
    key: 'transition_rate',
    title: '遷移割合',
  },
};

const CHANNEL_TYPE = {
  click: '広告クリック',
  search: '自然検索',
  direct: 'ダイレクト',
  referral: '外部リンク',
  site_content: 'サイト内',
};

const CHANNEL_TYPE_ID = {
  CLICK: '10',
  SEARCH: '20',
  REFERRAL: '30',
  DIRECT: '40',
  SITE_CONTENT: '50',
  VIEW: '60',
};

const CHANNEL_SUMMARY_KEY = {
  CLICK_SEARCH: '2',
  CLICK_SEARCH_REFERRAL_DIRECT: '1',
  CLICK_SEARCH_REFERRAL_DIRECT_SITECONTENT: '3',
};

const CHANNEL_TYPE_LABEL = {
  [CHANNEL_TYPE_ID.VIEW]: '広告ビュー',
  [CHANNEL_TYPE_ID.CLICK]: '広告クリック',
  [CHANNEL_TYPE_ID.SEARCH]: '自然検索',
  [CHANNEL_TYPE_ID.REFERRAL]: '外部リンク',
  [CHANNEL_TYPE_ID.DIRECT]: 'ダイレクト',
  [CHANNEL_TYPE_ID.SITE_CONTENT]: 'サイトコンテンツ',
};

const REPORT_TYPE = {
  CHANNEL: 'channel',
  ACCESS: 'access',
  CVATTR: 'cv_attr',
  CATEGORY: 'category',
  DETAIL: 'detail',
  PERIOD: 'period',
  CONVERSION: 'conversion',
  CATEGORY_PERIOD: 'cat_period',
  DETAIL_PERIOD: 'dtl_period',
  ADREPO: 'neo',
};

const CvFlowIndirectMetrics = [
  Metric.cntIndirect2,
  Metric.cntIndirect3,
  Metric.cntIndirect4,
  Metric.cntIndirect5,
  Metric.cntIndirect6,
  Metric.cntIndirect7,
  Metric.cntIndirect8,
  Metric.cntIndirect9,
  Metric.cntIndirect10,
];

const CvFlowFilterMetrics = [
  Metric.cntDirect,
  Metric.cntFirst,
  ...CvFlowIndirectMetrics,
];

const FILTER_CONTACT_HISTORY_DIRECT = 'direct';
const FILTER_CONTACT_HISTORY_INDIRECT2 = 'indirect2';
const FILTER_CONTACT_HISTORY_INDIRECT3 = 'indirect3';
const FILTER_CONTACT_HISTORY_INDIRECT4 = 'indirect4';
const FILTER_CONTACT_HISTORY_INDIRECT5 = 'indirect5';
const FILTER_CONTACT_HISTORY_INDIRECT6 = 'indirect6';
const FILTER_CONTACT_HISTORY_INDIRECT7 = 'indirect7';
const FILTER_CONTACT_HISTORY_INDIRECT8 = 'indirect8';
const FILTER_CONTACT_HISTORY_INDIRECT9 = 'indirect9';
const FILTER_CONTACT_HISTORY_INDIRECT10 = 'indirect10';
const FILTER_CONTACT_HISTORY_FIRST = 'first';

const FILTER_CONTACT_HISTORY = {
  [FILTER_CONTACT_HISTORY_DIRECT]: '直接効果',
  [FILTER_CONTACT_HISTORY_INDIRECT2]: '間接効果2',
  [FILTER_CONTACT_HISTORY_INDIRECT3]: '間接効果3',
  [FILTER_CONTACT_HISTORY_INDIRECT4]: '間接効果4',
  [FILTER_CONTACT_HISTORY_INDIRECT5]: '間接効果5',
  [FILTER_CONTACT_HISTORY_INDIRECT6]: '間接効果6',
  [FILTER_CONTACT_HISTORY_INDIRECT7]: '間接効果7',
  [FILTER_CONTACT_HISTORY_INDIRECT8]: '間接効果8',
  [FILTER_CONTACT_HISTORY_INDIRECT9]: '間接効果9',
  [FILTER_CONTACT_HISTORY_INDIRECT10]: '間接効果10',
  [FILTER_CONTACT_HISTORY_FIRST]: '初回接触',
};

// Define contact history's default values
const FILTER_CONTACT_HISTORY_DEFAULT = {
  contact_history: {
    values: [
      FILTER_CONTACT_HISTORY_DIRECT,
      FILTER_CONTACT_HISTORY_INDIRECT2,
      FILTER_CONTACT_HISTORY_INDIRECT3,
      FILTER_CONTACT_HISTORY_INDIRECT4,
      FILTER_CONTACT_HISTORY_INDIRECT5,
      FILTER_CONTACT_HISTORY_INDIRECT6,
      FILTER_CONTACT_HISTORY_INDIRECT7,
      FILTER_CONTACT_HISTORY_INDIRECT8,
      FILTER_CONTACT_HISTORY_INDIRECT9,
      FILTER_CONTACT_HISTORY_INDIRECT10,
      FILTER_CONTACT_HISTORY_FIRST,
    ],
    order: 22,
    title: Metric.contactTiming.title,
    type: 0,
  },
};

const COMMA_SEPARATOR = '、';
const ONE_BYTE_COMMA_SEPERATOR = ',';

const DATE_RANGE_TYPE = {
  CUSTOM: 0,

  TODAY: 1,
  YESTERDAY: 2,
  THIS_WEEK: 3,
  LAST_WEEK: 4,
  THIS_MONTH: 5,
  LAST_MONTH: 6,
  DAYS_AGO_7_INCLUDED: 7,
  DAYS_AGO_14_INCLUDED: 8,
  DAYS_AGO_30_INCLUDED: 9,
  DAYS_AGO_7_EXCLUDED: 10,
  DAYS_AGO_14_EXCLUDED: 11,
  DAYS_AGO_30_EXCLUDED: 12,
  THIS_MONTH_EXCLUDED: 13,
  LAST_WEEK_BUSINESS_DAYS: 14,

  // Compare period types
  LAST_PERIOD: 101,
  LAST_YEAR_SAME_PERIOD: 102,
  LAST_WEEK_THIS_PERIOD: 103,
  LAST_MONTH_THIS_PERIOD: 104,
};

const CURRENT_PERIOD_AVAILABLE_PRESETS = [
  DATE_RANGE_TYPE.TODAY,
  DATE_RANGE_TYPE.YESTERDAY,
  DATE_RANGE_TYPE.THIS_WEEK,
  DATE_RANGE_TYPE.LAST_WEEK,
  DATE_RANGE_TYPE.THIS_MONTH,
  DATE_RANGE_TYPE.LAST_MONTH,
  DATE_RANGE_TYPE.DAYS_AGO_7_INCLUDED,
  DATE_RANGE_TYPE.DAYS_AGO_14_INCLUDED,
  DATE_RANGE_TYPE.DAYS_AGO_30_INCLUDED,
  DATE_RANGE_TYPE.DAYS_AGO_7_EXCLUDED,
  DATE_RANGE_TYPE.DAYS_AGO_14_EXCLUDED,
  DATE_RANGE_TYPE.DAYS_AGO_30_EXCLUDED,
  DATE_RANGE_TYPE.THIS_MONTH_EXCLUDED,
  DATE_RANGE_TYPE.LAST_WEEK_BUSINESS_DAYS,
];

const COMPARED_PERIOD_AVAILABLE_PRESETS = [
  DATE_RANGE_TYPE.LAST_PERIOD,
  DATE_RANGE_TYPE.LAST_YEAR_SAME_PERIOD,
  DATE_RANGE_TYPE.LAST_WEEK_THIS_PERIOD,
  DATE_RANGE_TYPE.LAST_MONTH_THIS_PERIOD,
];

const CALENDAR_MONTH_FORMAT = 'YYYY/MM';

const CALENDAR_DAY_FORMAT = 'YYYY/MM/DD';

const CALENDAR_INPUT_ALLOWED_FORMAT = 'Y/M/D';

const COMPARE_PERIOD_X_LABEL_DATE_FORMAT = 'M/DD (dd)';

const COMPARE_PERIOD_X_LABEL_MONTH_FORMAT = 'YYYY/MM';

const SCREEN_DATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm:ss';

const UPDATE_TIME_FORMAT = 'YYYY/MM/DD HH:mm';

const API_DATE_FORMAT = 'YYYY-MM-DD';

const API_MONTH_FORMAT = 'YYYY-MM';

const CURRENCY_SYMBOL = '¥';

const CURRENCY_SYMBOL_2 = '円';

const PERCENTAGE_SYMBOL = '%';

const TIME_SECONDS_SYMBOL = '秒';

const FREQUENCY_MAXIMUM = 11;

const JA_JP = 'ja-JP';

const JPY = 'JPY';

const FIELD = {
  CV_NAME: 'cv_name',
  CV_DATE: 'cv_date',
  TRACKING_DATA: 'tracking_data',
  MEMBER_NAME: 'member_name',
  OTHER1: 'other1',
  OTHER2: 'other2',
  OTHER3: 'other3',
  OTHER4: 'other4',
  OTHER5: 'other5',
  TERMINAL_TYPE: 'terminal_type',
  LATENCY_TIME: 'latency_time',
  CONTACT_CNT: 'contact_cnt',
  AD_DIRECT: 'ad_direct',
  AD_INDIRECT: 'ad_indirect',
  INDIRECT_2: 'indirect_2',
  INDIRECT_3: 'indirect_3',
  INDIRECT_4: 'indirect_4',
  INDIRECT_5: 'indirect_5',
  INDIRECT_6: 'indirect_6',
  INDIRECT_7: 'indirect_7',
  INDIRECT_8: 'indirect_8',
  INDIRECT_9: 'indirect_9',
  INDIRECT_10: 'indirect_10',
  AD_FIRST: 'ad_first',
  ACCESS_TIME: 'access_time',
  CHANNEL_ACCESS_TYPE: 'channel_access_type',
  MEDIA_TYPE: 'media_type',
  AD_GROUP1_ID: 'ad_group1_id',
  AD_GROUP2_ID: 'ad_group2_id',
  AD_GROUP_1: 'ad_group_1',
  AD_GROUP_2: 'ad_group_2',
  AD_ID: 'ad_id',
  AD_NAME: 'ad_name',
  LANDING_PAGE_URL: 'landing_page_url',
  AVG_PERIOD: 'avg_period',
  AVG_CONTACT_HISTORY: 'avg_contact_history',
  TOTAL_AMOUNT: 'total_amount',
  AVG_AMOUNT: 'avg_amount',
  AD_NOTE: 'ad_note',
  AD_DATE: 'ad_date',
  IMPRESSION: 'impression',

  /**
   * Clicks / inflow
   */
  CNT_ACCESS: 'cnt_access',
  CNT_CLICK: 'cnt_click',
  /**
   * CTR
   */
  PER_UNIQUE: 'per_unique',
  CNT_SESSION: 'cnt_session',
  CNT_PV: 'cnt_pv',
  PER_PV: 'per_pv',
  CNT_RETURN: 'cnt_return',
  PER_RETURN: 'per_return',
  TOTAL_STAY_TIME: 'total_stay_time',
  TOTAL_STAY_TIME_AVG: 'total_stay_time_avg',
  LATENCY_TIME_TOTAL: 'latency_time_total',
  LATENCY_TIME_AVERAGE: 'latency_time_average',
  CV: 'cv',
  CV_CNT: 'cv_cnt',
  CV_TOTAL: 'cv_total',
  CVR: 'cvr',
  CVR_TOTAL: 'cvr_total',
  INDIRECT_CNT_2: 'indirect_cnt_2',
  INDIRECT_CNT_3: 'indirect_cnt_3',
  INDIRECT_CNT_4: 'indirect_cnt_4',
  INDIRECT_CNT_5: 'indirect_cnt_5',
  INDIRECT_CNT_6_10: 'indirect_cnt_6_10',
  INDIRECT_TOTAL: 'indirect_total',
  DIRECT_RATE: 'direct_rate',
  INDIRECT_RATE: 'indirect_rate',
  AD_FIRST_CNT: 'ad_first_cnt',
  RCV: 'rcv',
  AMOUNT: 'amount',
  RAMOUNT: 'ramount',
  COST: 'cost',
  CPC: 'cpc',
  CPA: 'cpa',
  TCPA: 'tcpa',
  ROAS: 'roas',
  TROAS: 'troas',
  MEDIA_NAME: 'media_name',
  MEDIA_ID: 'media_id',
  CTR: 'ctr',
};

const FIELD_PREFIX_CONTACT = {
  [DisplayItems.CONTACT_DIRECT]: `${DisplayItems.CONTACT_DIRECT}_`,
  [DisplayItems.CONTACT_INDIRECT2]: `${DisplayItems.CONTACT_INDIRECT2}_`,
  [DisplayItems.CONTACT_INDIRECT3]: `${DisplayItems.CONTACT_INDIRECT3}_`,
  [DisplayItems.CONTACT_INDIRECT4]: `${DisplayItems.CONTACT_INDIRECT4}_`,
  [DisplayItems.CONTACT_INDIRECT5]: `${DisplayItems.CONTACT_INDIRECT5}_`,
  [DisplayItems.CONTACT_INDIRECT6]: `${DisplayItems.CONTACT_INDIRECT6}_`,
  [DisplayItems.CONTACT_INDIRECT7]: `${DisplayItems.CONTACT_INDIRECT7}_`,
  [DisplayItems.CONTACT_INDIRECT8]: `${DisplayItems.CONTACT_INDIRECT8}_`,
  [DisplayItems.CONTACT_INDIRECT9]: `${DisplayItems.CONTACT_INDIRECT9}_`,
  [DisplayItems.CONTACT_INDIRECT10]: `${DisplayItems.CONTACT_INDIRECT10}_`,
  [DisplayItems.CONTACT_FIRST]: `${DisplayItems.CONTACT_FIRST}_`,
};

const FIELD_PREFIX = {
  AD_FIRST: 'ad_first_',
  AD_DIRECT: 'ad_direct_',
  INDIRECT_CNT: 'indirect_cnt_',
  INDIRECT_2: 'indirect_2_',
  INDIRECT_3: 'indirect_3_',
  INDIRECT_4: 'indirect_4_',
  INDIRECT_5: 'indirect_5_',
  INDIRECT_6: 'indirect_6_',
  INDIRECT_7: 'indirect_7_',
  INDIRECT_8: 'indirect_8_',
  INDIRECT_9: 'indirect_9_',
  INDIRECT_10: 'indirect_10_',
  CNT_DIRECT_CV: 'cnt_direct_cv_',
  CNT_DIRECT_RATE_CV: 'cnt_direct_rate_cv_',
  ...FIELD_PREFIX_CONTACT,
  CNT_NEW_CV: 'cnt_new_cv_',
  NEW_CVR: 'new_cvr_',
  CNT_CV_CONTRIBUTE: `${DisplayItems.CNT_CV_CONTRIBUTE}_`,
  CNT_CVR_CONTRIBUTE: `${DisplayItems.CNT_CVR_CONTRIBUTE}_`,
};

const FIELD_API_RESPONSE = {
  [field.CHANNEL_ACCESS_TYPE]: {
    field: field.CHANNEL_ACCESS_TYPE,
    key: field.CHANNEL,
    value: field.CHANNEL,
  },
  [field.CATEGORY]: {
    field: field.CATEGORY,
    key: field.CATEGORY_ID,
    value: field.CATEGORY,
  },
  [field.MEDIA_ID]: {
    field: field.MEDIA_ID,
    key: field.MEDIA_ID,
    value: field.MEDIA_NAME,
  },
  [field.AD_GROUP1]: {
    field: field.AD_GROUP1,
    key: field.AD_GROUP1_ID,
    value: field.AD_GROUP1_NAME,
  },
  [field.AD_GROUP2]: {
    field: field.AD_GROUP2,
    key: field.AD_GROUP2_ID,
    value: field.AD_GROUP2_NAME,
  },
  [field.SYNC_CATEGORY]: {
    field: field.SYNC_CATEGORY,
    key: field.SYNC_CATEGORY_ID,
    value: field.SYNC_CATEGORY,
  },
  [field.MEDIA_SIDE_CAMPAIGN]: {
    field: field.MEDIA_SIDE_CAMPAIGN,
    key: field.MEDIA_SIDE_CAMPAIGN_ID,
    value: field.MEDIA_SIDE_CAMPAIGN_NAME,
  },
  [field.MEDIA_SIDE_GROUP]: {
    field: field.MEDIA_SIDE_GROUP,
    key: field.MEDIA_SIDE_GROUP_ID,
    value: field.MEDIA_SIDE_GROUP_NAME,
  },
  [field.MEDIA_SIDE_AD_NAME]: {
    field: field.MEDIA_SIDE_AD_NAME,
    key: field.MEDIA_SIDE_AD_ID,
    value: field.MEDIA_SIDE_AD_NAME,
  },
  [field.AGENCY]: {
    field: field.AGENCY,
    key: field.AGENCY_ID,
    value: field.AGENCY_NAME,
  },
  [field.CONTENT_CATEGORY]: {
    field: field.CONTENT_CATEGORY,
    key: field.CONTENT_CATEGORY_ID,
    value: field.CONTENT_CATEGORY_NAME,
  },
  [field.MEDIA_ACCOUNT]: {
    field: field.MEDIA_ACCOUNT,
    key: field.MEDIA_ACCOUNT_ID,
    value: field.MEDIA_ACCOUNT_NAME,
  },
};

const CURRENCY_FIELDS = [
  field.COST,
  field.CPC,
  field.CPA,
  FIELD.TOTAL_AMOUNT,
  field.RAMOUNT,
  field.TCPA,
  field.AMOUNT,
  field.AMOUNT_AVG,
  field.BASE_CPC,
  field.BASE_CPCONV,

  field.COL2_COST,
  field.COL3_COST,

  field.NEW_CPA_ACTUAL_RESULTS,
  field.NEW_CPA_UPPER_LIMIT,
  field.NEW_CPA_DIFFERENCE,
  field.F2_CPA,
  field.F1_AVERAGE_AMOUNT,
  field.F2_AVERAGE_AMOUNT,
  field.F3_OVER_AVERAGE_AMOUNT,
  field.LTV_AMOUNT,
  field.LTV_AMOUNT_TOTAL,
  field.LTV_PROFIT,
  field.LTV_PROFIT_TOTAL,
  field.MARGINAL_PROFIT,

  field.RAMOUNT_LINKED_BEFORE,
  field.RAMOUNT_DIFFERENCE,
];

const PERCENTAGE_FIELDS = [
  field.CNT_DIRECT_RATE_CV,
  field.CVR_TOTAL,
  field.CTR,
  field.PER_RETURN,
  field.DIRECT_RATE,
  field.INDIRECT_RATE,
  field.ROAS,
  field.TROAS,
  field.AD_COST_RATIO,
  field.REALLOCATION_SALES_RATIO,
  field.REALLOCATION_CV_RATIO,
  field.RCV_COST_EFFECTIVENESS,
  field.RAMOUNT_COST_EFFECTIVENESS,

  field.NEW_CVR,
  field.NEW_CVR_TOTAL,
  field.F2_CV_RATIO,
  field.F3_CV_RATIO,
  field.ROI,

  field.CV_TOTAL_RATE,
  field.CNT_FIRST_RATE,
  field.RCV_RATE,
  field.RAMOUNT_RATE,
  field.TRANSITION_RATE,

  DisplayItems.RATE,
  DisplayItems.PER_UU,
  DisplayItems.PER_SESSION,
  DisplayItems.PER_PV,
  DisplayItems.CNT_CVR_CONTRIBUTE,
  DisplayItems.CVR_CONTRIBUTE_TOTAL,
];

const NUMBER_FIELDS = [
  field.COST,
  field.CNT_DIRECT_CV,
  field.CNT_DIRECT_RATE_CV,
  field.CV_TOTAL,
  field.CV_TOTAL_LINKED_BEFORE,
  field.CV_TOTAL_DIFFERENCE,
  field.CV_TOTAL_RATE,
  field.CVR_TOTAL,
  field.INDIRECT_RATE,
  field.DIRECT_RATE,
  field.CPA,
  field.TCPA,
  field.CPC,
  field.AMOUNT,
  field.AMOUNT_AVG,
  field.AMOUNT,
  field.RAMOUNT,
  field.RAMOUNT_LINKED_BEFORE,
  field.RAMOUNT_DIFFERENCE,
  field.RAMOUNT_RATE,
  field.TROAS,
  field.ROAS,
  field.CNT_ACCESS,
  field.CNT_PV,
  field.CNT_CV,
  field.CNT_FIRST,
  field.CNT_FIRST_LINKED_BEFORE,
  field.CNT_FIRST_DIFFERENCE,
  field.CNT_FIRST_RATE,
  field.CNT_CONTACT,
  field.PER_PAGE_VIEW,
  field.CNT_RETURN,
  field.PER_RETURN,
  field.CNT_SESSION,
  field.INDIRECT_2,
  field.INDIRECT_3,
  field.INDIRECT_4,
  field.INDIRECT_5,
  field.CNT_INDIRECT_2,
  field.CNT_INDIRECT_3,
  field.CNT_INDIRECT_4,
  field.CNT_INDIRECT_5,
  field.CNT_INDIRECT_OTHER,
  field.IMPRESSION,
  field.CTR,
  field.AD_FIRST,
  field.RCV,
  field.RCV_LINKED_BEFORE,
  field.RCV_DIFFERENCE,
  field.RCV_RATE,
  field.INDIRECT_TOTAL,
  field.CONTACT_HISTORY_AVG,
  field.AD_COST_RATIO,
  field.RCV_COST_EFFECTIVENESS,
  field.RAMOUNT_COST_EFFECTIVENESS,
  field.REALLOCATION_SALES_RATIO,
  field.REALLOCATION_CV_RATIO,
  field.BASE_CPC,
  field.BASE_CPCONV,
  field.COL2_NUM,
  field.COL2_COST,
  field.COL3_NUM,
  field.COL3_COST,
  field.CNT_NEW_CV,
  field.CNT_NEW_CV_TOTAL,
  field.NEW_CVR,
  field.NEW_CVR_TOTAL,
  field.F2_CV_RATIO,
  field.F3_CV_RATIO,
  field.NEW_CPA_ACTUAL_RESULTS,
  field.NEW_CPA_UPPER_LIMIT,
  field.NEW_CPA_DIFFERENCE,
  field.F2_CPA,
  field.F1_AVERAGE_AMOUNT,
  field.F2_AVERAGE_AMOUNT,
  field.F3_OVER_AVERAGE_AMOUNT,
  field.LTV_AMOUNT,
  field.LTV_AMOUNT_TOTAL,
  field.LTV_PROFIT,
  field.LTV_PROFIT_TOTAL,
  field.MARGINAL_PROFIT,
  field.ROI,

  DisplayItems.RANK,
  DisplayItems.CNT,
  DisplayItems.RATE,
  DisplayItems.CV,
  DisplayItems.PER_PV,
  DisplayItems.PER_SESSION,
  DisplayItems.CNT_UU,
  DisplayItems.PER_UU,
  DisplayItems.CNT_ENTRANCE,
  DisplayItems.CNT_EXIT,
  DisplayItems.CNT_CV_CONTRIBUTE,
  DisplayItems.CV_CONTRIBUTE_TOTAL,
  DisplayItems.CNT_CVR_CONTRIBUTE,
  DisplayItems.CVR_CONTRIBUTE_TOTAL,

  FIELD_PREFIX.CNT_DIRECT_CV,
  FIELD_PREFIX.CNT_DIRECT_RATE_CV,
  FIELD_PREFIX.CNT_NEW_CV,
  FIELD_PREFIX.NEW_CVR,
  FIELD_PREFIX.CNT_CV_CONTRIBUTE,
  FIELD_PREFIX.CNT_CVR_CONTRIBUTE,

  field.TRANSITION_RATE,
];

const DECIMAL_FIELDS = [
  field.CTR,
  field.CVR_TOTAL,
  field.CNT_DIRECT_RATE_CV,
  field.RCV,
  field.ROAS,
  field.TROAS,
  field.RAMOUNT,
  field.DIRECT_RATE,
  field.INDIRECT_RATE,
  field.PER_PAGE_VIEW,
  field.PER_RETURN,
  field.COL2_NUM,
  field.COL3_NUM,
  field.AD_COST_RATIO,
  field.REALLOCATION_SALES_RATIO,
  field.REALLOCATION_CV_RATIO,
  field.RCV_COST_EFFECTIVENESS,
  field.RCV_LINKED_BEFORE,
  field.RCV_DIFFERENCE,
  field.RCV_RATE,
  field.RAMOUNT_COST_EFFECTIVENESS,
  field.RAMOUNT_LINKED_BEFORE,
  field.RAMOUNT_DIFFERENCE,
  field.RAMOUNT_RATE,
  DisplayItems.RATE,
  DisplayItems.PER_UU,
  DisplayItems.PER_SESSION,
  DisplayItems.PER_PV,
  DisplayItems.CNT_CVR_CONTRIBUTE,
  DisplayItems.CVR_CONTRIBUTE_TOTAL,

  FIELD_PREFIX.CNT_DIRECT_RATE_CV,
  FIELD_PREFIX.CNT_CVR_CONTRIBUTE,
];

const PERIOD_FIELDS = [
  field.LATENCY_TIME_AVG,
  field.TOTAL_LATENCY_TIME,
  field.TOTAL_STAY_TIME_AVG,
  field.TOTAL_STAY_TIME,
  field.LATENCY_TIME,
  DisplayItems.CV_CONTACT_LATENCY_TIME_AVG,
];

const PERIOD_MONTHS_FIELDS = [
  field.PAYBACK_PERIOD_TARGET,
  field.PAYBACK_PERIOD_PREDICTION,
  field.PAYBACK_PERIOD_DIFFERENCE,
];

const CHANNNEL_FIELDS = [DisplayItems.CHANNEL, field.CHANNEL];

const TERMINAL_FIELDS = [DisplayItems.TERMINAL_TYPE, DisplayItems.DEVICE];
const MEASUREMENT_FIELDS = [DisplayItems.MEASUREMENT_TYPE];
const AD_TYPE_FIELDS = [DisplayItemsAdManagement.AD_TYPE];

const FREQUENCY_FIELDS = [
  field.CONTACT_HISTORY_AVG,
  field.CNT_CONTACT,
  DisplayItems.CONTACT_AVG,
];

const URL_FIELDS = [field.LANDING_PAGE_URL, field.LANDING_PAGE_DOMAIN];

const DATE_FIELDS = [
  DisplayItemsAdManagement.AD_START_DATE,
  DisplayItemsAdManagement.AD_END_DATE,
];

const DATE_TIME_FIELDS = [
  field.CV_DATE,
  field.ACCESS_TIME,
  field.REGIST_DATE,
  field.UPDATE_DATE,
  DisplayItems.CV_ACCESS_TIME,
  DisplayItemsAdManagement.REGISTER_DATE,
  DisplayItemsAdManagement.UPDATED_DATE,

  // Media Sync. Management
  field.LINKED_DATE,

  // Impression Ad Cost
  field.EXECUTE_DATE,
];

const DATE_RAGNGE_FIELDS = [DisplayItems.AD_DATE];

const CV_FIELD_PREFIX = 'cnt_direct_cv_';
const NEW_CV_FIELD_PREFIX = 'cnt_new_cv';

const CVR_FIELD_PREFIX = 'cnt_direct_rate_cv_';
const NEW_CVR_FIELD_PREFIX = 'new_cvr_';

const CV_METRICS = [
  field.CNT_DIRECT_CV,
  field.CNT_DIRECT_RATE_CV,
  DisplayItems.CNT_CV_CONTRIBUTE,
  DisplayItems.CNT_CVR_CONTRIBUTE,
];
const NEW_CV_METRICS = [field.CNT_NEW_CV, field.NEW_CVR];
const NEW_CPA_METRICS = [
  field.NEW_CPA_ACTUAL_RESULTS,
  field.NEW_CPA_UPPER_LIMIT,
  field.NEW_CPA_DIFFERENCE,
];
const PAYBACK_PERIOD_METRICS = [
  field.PAYBACK_PERIOD_TARGET,
  field.PAYBACK_PERIOD_PREDICTION,
  field.PAYBACK_PERIOD_DIFFERENCE,
];

const COLUMN_NOWRAP = [
  ...NUMBER_FIELDS,
  ...DATE_FIELDS,
  ...DATE_TIME_FIELDS,
  ...DATE_RAGNGE_FIELDS,
  ...PERIOD_FIELDS,
  ...PERIOD_MONTHS_FIELDS,
  field.PERIOD,
  field.TERMINAL_TYPE,
  field.MEASUREMENT_TYPE,
  field.PROGRAM_URL,
  'period',
];

/**
 * Display [...] & tooltip for screen's columns if width of text over column width
 */
const COLUMN_NOWRAP_WITH_TOOLTIP = [
  field.CHANNEL_ACCESS_TYPE,
  field.CATEGORY,
  field.AD_GROUP1,
  field.AD_GROUP2,
  field.MEDIA_ACCOUNT,
  field.SYNC_CATEGORY,
  field.MEDIA_SIDE_CAMPAIGN,
  field.MEDIA_SIDE_GROUP,
  field.MEDIA_SIDE_AD_ID,
  field.MEDIA_SIDE_AD_NAME,
  field.MEDIA_SIDE_FINAL_URL,

  // CV attributes
  field.CV_NAME,
  field.MEMBER_NAME,
  field.TRACKING_DATA,
  field.OTHER1,
  field.OTHER2,
  field.OTHER3,
  field.OTHER4,
  field.OTHER5,
  field.CHANNEL,
  field.AD_ID,
  field.AD_NAME,
  field.LANDING_PAGE_DOMAIN,
  field.LANDING_PAGE_URL,

  // AD management
  field.PAGE_TITLE,
  field.AD_ID,
  field.KEYWORD,
  field.MEDIA_ID,
  field.AD_NOTE,
  field.URL,
  field.PARENT_AD_ID,
  field.PASTE_URL,
  field.PASTE_URL_PARALLEL,
  field.PASTE_URL_NS_CNAME,

  // Media Sync. Management
  field.MEDIA_NAME,
  field.MEDIA_DISPLAY_NAME,
  field.ADVERTISER_ID,
  field.AGENCY_NAME,
  field.EBIS_MEDIA_NAME,
  field.IS_IMPORTING_COSTS,

  // lpo
  field.LINK_URL,
  field.LINK_PAGE_TITLE,
];
const CHART_COLORS = [
  { primary: '#EEEEEE', secondary: '#EEEEEE' },
  { primary: '#E26262', secondary: '#EDA0A0' },
  { primary: '#CEB995', secondary: '#E1D5BF' },
  { primary: '#748BD2', secondary: '#ABB9E4' },
  { primary: '#364E98', secondary: '#8694C1' },
  { primary: '#88BC8F', secondary: '#B7D7BC' },
  { primary: '#D5E262', secondary: '#E6EDA0' },
  { primary: '#CE95C3', secondary: '#E1BFDB' },
  { primary: '#FFC133', secondary: '#FFD370' },
  { primary: '#AF7F7F', secondary: '#CFB2B2' },
  { primary: '#97C1E5', secondary: '#C1DAEF' },
  { primary: '#F6C4C4', secondary: '#F9D6D6' },
  { primary: '#DCD279', secondary: '#E6DFA1' },
  { primary: '#C6E7F3', secondary: '#D7EEF7' },
  { primary: '#74A797', secondary: '#9DC1B6' },
  { primary: '#A9DEB4', secondary: '#C3E8CA' },
  { primary: '#EBD1E6', secondary: '#F1DEED' },
  { primary: '#F1A0B9', secondary: '#F5BCCE' },
  { primary: '#C2C4DC', secondary: '#D4D6E6' },
  { primary: '#FFE3A2', secondary: '#FFEBBE' },
  { primary: '#A6B9C2', secondary: '#C1CED4' },
];

const TABLE_ROWS_PER_PAGE = 200;

const EXPORT_TYPE = {
  JPEG: 'img/jpeg',
  PNG: 'img/png',
};

const CSV_MAX_DOWNLOADABLE_RECORDS = 3 * 10 ** 6;
const CSV_DOWNLOAD_TYPE = {
  CONTENT: '',
  VARIOUS_TAGS: 'various-tags',
  CONTENT_CATEGORY: 'content-category',
};

const BOOKMARK_FUNC_NAME = {
  CATEGORY_ANALYZE: 'FUNC_CATEGORY_ANALYZE',
  COMPARED_PERIOD: 'FUNC_COMP_PERIOD',
  LPO_PERIOD: 'FUNC_LPO_PERIOD',
  LOG_PERIOD_ANALYZE: 'FUNC_LOG_PERIOD_ANALYZE',
};

const UNREGISTERED_IDS = ['9999999999999999989'];
const COST_LINKING_EMPTY_IDS = ['9999999999999999999'];

const UNREGISTERED_IDS_METRICS = [
  Metric.adGroup1,
  Metric.adGroup2,
  Metric.media,
];

const UNREGISTERED_ID_FILTER_KEY = '0';
const MEDIA_ACCOUNT_UNREGISTERED_ID_FILTER_KEY = '#:#';
const CAMPAIGN_GROUP_UNREGISTERED_ID_FILTER_KEY = '#:#:#';

const UNREGISTERED_DISPLAY_VALUE = '[未登録]';

const UNSYNCED_DISPLAY_VALUE = '[取得中]';

const PAGE_DELETED_DISPLAY_VALUE = '削除ページ';
const PAGE_ID_OVER_LIMITED = '#0';

const UNREGISTERED_UNSYNCED_DISPLAY_VALUE = [
  UNREGISTERED_DISPLAY_VALUE,
  UNSYNCED_DISPLAY_VALUE,
].join('/');

const NO_PERMISSION_DISPLAY_VALUE = '********';

const COST_LINKING_EMPTY_DISPLAY_VALUE = '[カテゴリ別表示回数/広告コスト]';

const CHART_EXPORT_IMAGE_ID = 'chart-export-area';

const CHART_EXPORT_EXCLUDED_CLASSNAME = 'chart-export-excluded';

const HIGHCHARTS_EXPORT_EXCLUDED_CLASSLIST = [
  CHART_EXPORT_EXCLUDED_CLASSNAME,
  'highcharts-tooltip',
  'highcharts-crosshair',
  'highcharts-halo',
];

const JP_DAY_OF_WEEK = ['月', '火', '水', '木', '金', '土', '日'];

const subHeaderDefinitions = [
  {
    name: 'indirect',
    text: '間接効果',
    children: [
      field.CNT_INDIRECT_2,
      field.CNT_INDIRECT_3,
      field.CNT_INDIRECT_4,
      field.CNT_INDIRECT_5,
      field.CNT_INDIRECT_OTHER,
      field.INDIRECT_TOTAL,
    ],
  },
  {
    name: 'rate',
    text: '直間比率',
    children: [field.DIRECT_RATE, field.INDIRECT_RATE],
  },
];

const DEFAULT_SELECTED_CATEGORIES = 10;
const MAX_CATEGORIES_SELECTION = 20;
const MAX_CUSTOM_VIEW_COUNT = 20;
const MAX_ITEMS_DISPLAY_IN_COLUMN = 50;

// Setting Mail
const MAX_SELECTED_MEDIA_SETTING_MAIL = 5;

const PERIOD_COMPARABLE_SCREEN_IDS = [
  COMPARE_PERIOD,
  CATEGORY_ANALYZE,
  LPO_PERIOD,
  LOG_PERIOD_ANALYZE,
];

const CUSTOM_DISPLAY_FIELDS = [
  field.AD_GROUP1,
  field.AD_GROUP2,
  field.AD_NOTE,
  field.OTHER1,
  field.OTHER2,
  field.OTHER3,
  field.OTHER4,
  field.OTHER5,
];

const FILTER_CONTACT_HISTORY_LENGTH = 11;

const FILTER_UNREGISTERED_ITEM = {
  [+UNREGISTERED_ID_FILTER_KEY]: {
    id: +UNREGISTERED_ID_FILTER_KEY,
    name: UNREGISTERED_DISPLAY_VALUE,
    order: 0,
  },
};

const MEDIA_ACCOUNT_FILTER_UNREGISTERED_ITEM = {
  [MEDIA_ACCOUNT_UNREGISTERED_ID_FILTER_KEY]: {
    id: MEDIA_ACCOUNT_UNREGISTERED_ID_FILTER_KEY,
    name: UNREGISTERED_DISPLAY_VALUE,
    order: 0,
  },
};

const CAMPAIGN_GROUP_FILTER_UNREGISTERED_ITEM = {
  [CAMPAIGN_GROUP_UNREGISTERED_ID_FILTER_KEY]: {
    id: CAMPAIGN_GROUP_UNREGISTERED_ID_FILTER_KEY,
    name: UNREGISTERED_UNSYNCED_DISPLAY_VALUE,
    order: 0,
  },
};

const MASTER_DATA_FILTER_LIMIT = 500;

const API_UNLIMITED_VALUE = -1;

const UNLIMITED_VALUE = 0;

const MASTER_DATA_TYPE = {
  REPORT: 'report',
  SETTING: 'setting',
};

const DASHBOARD_ANALYSIS_TYPE = {
  PER_MEDIA: 1,
  PER_MEDIA_LAST_7_DAYS: 2,
  TRANSITIONS_PER_MEDIA_CURRENT_MONTH: 3,
  SEARCH_CHANGED_FACTORS: 4,
  COST_ALLOCATION_REVIEW: 5,
  OTHER_CHANGES: 6,
};

const MAINTENANCE_TYPE = {
  INACTIVE: 1,
  IN_PROGRESS: 2,
  NOTICE: 3,
};

const AD_MANAGEMENT_MODE = {
  AD: 'ad',
  LISTING: 'listing',
  ALL: 'all',
  DELETED: 'deleted',
};

const MEASUREMENT_TYPE_REDIRECT = '1';
const MEASUREMENT_TYPE_DIRECT = '2';

const CSV_MAX_SIZE = 30 * 1024 * 1024;

const DEFAULT_MAX_LINE_CSV = 100000;

const CONTENT_TYPE = {
  CSV: 'text/csv',
  PNG: 'image/png',
  JPEG: 'image/jpeg',
  GIF: 'image/gif',
};

const AD_MANAGEMENT_STATUS_ACTION = {
  DELETE: '',
  DROP: 'drop',
  RESTORE: 'restore',
};

const COLUMN_TEXT_ALIGN_RIGHT = [
  MARGIN_F1,
  MARGIN_F2,
  MARGIN_F3,
  ...PERIOD_MONTHS_FIELDS,
];

const AGENCY_LIMIT = 50;
const AGENCY_FILTER_LIMIT = 51;

const PRIORITY_AXIS_MEDIA_SYNC = 'priority_axis_media_sync';
const PRIORITY_AXIS_EBIS = 'priority_axis_ebis';
const PRIORITY_AXIS_BOTH = 'priority_axis_both';

const MEDIA_SIDE_ITEMS = [
  field.CHANNEL,
  field.CHANNEL_ACCESS_TYPE,
  field.MEDIA_SIDE_AD_ID,
  field.MEDIA_SIDE_AD_NAME,
  field.MEDIA_SIDE_CAMPAIGN,
  field.MEDIA_SIDE_GROUP,
  field.SYNC_CATEGORY,
];

const MEDIA_SIDE_ITEMS_AD_MANAGEMENT = [
  DisplayItemsAdManagement.MEDIA_SIDE_FINAL_URL,
  DisplayItemsAdManagement.MEDIA_ACCOUNT,
  DisplayItemsAdManagement.MEDIA_SIDE_CAMPAIGN,
  DisplayItemsAdManagement.MEDIA_SIDE_GROUP,
  DisplayItemsAdManagement.MEDIA_SIDE_AD_NAME,
  DisplayItemsAdManagement.MEDIA_SIDE_AD_ID,
];

const EBIS_ITEMS = [field.AD_ID, field.AD_NAME];
const SYNC_SOURCE_ITEMS = [field.IMPRESSION, field.COST];
const EBIS_SOURCE_ITEMS = [
  field.CNT_ACCESS,
  field.CNT_SESSION,
  field.CNT_RETURN,
  field.CNT_PV,
  field.PER_PAGE_VIEW,
  field.PER_RETURN,
  field.TOTAL_STAY_TIME,
  field.TOTAL_STAY_TIME_AVG,
  field.LATENCY_TIME,
  field.LATENCY_TIME_AVG,
  field.TOTAL_LATENCY_TIME,
  field.RCV,
  field.RCV_LINKED_BEFORE,
  field.RCV_DIFFERENCE,
  field.RCV_RATE,
  field.REALLOCATION_CV_RATIO,
  field.RAMOUNT,
  field.RAMOUNT_LINKED_BEFORE,
  field.RAMOUNT_DIFFERENCE,
  field.RAMOUNT_RATE,
  field.REALLOCATION_SALES_RATIO,
  field.AD_FIRST,
  field.CNT_FIRST,
  field.CNT_FIRST_LINKED_BEFORE,
  field.CNT_FIRST_DIFFERENCE,
  field.CNT_FIRST_RATE,
  field.AMOUNT,
  field.RAMOUNT,
  field.CV_TOTAL,
  field.CV_TOTAL_LINKED_BEFORE,
  field.CV_TOTAL_DIFFERENCE,
  field.CV_TOTAL_RATE,
  field.CVR_TOTAL,
  field.CNT_INDIRECT_2,
  field.CNT_INDIRECT_3,
  field.CNT_INDIRECT_4,
  field.CNT_INDIRECT_5,
  field.CNT_INDIRECT_OTHER,
  field.INDIRECT_TOTAL,
  field.INDIRECT_RATE,
  field.DIRECT_RATE,
  field.CTR,
  field.CPC,
  field.CPA,
  field.TCPA,
  field.TROAS,
  field.ROAS,
  field.REALLOCATION_CV_RATIO,
  field.REALLOCATION_SALES_RATIO,
  field.RAMOUNT_COST_EFFECTIVENESS,
  field.RCV_COST_EFFECTIVENESS,
  field.AD_COST_RATIO,
];

const METRICS_VIEW_HIDE = '2';
const METRICS_VIEW_SIMPLE = '3';

// When these fields have no value (null or ""), display "-"
const HYPHEN_FIELDS = [
  field.NEW_CPA_UPPER_LIMIT,
  field.NEW_CPA_DIFFERENCE,
  field.F3_OVER_AVERAGE_AMOUNT,
  field.F2_CV_RATIO,
  field.F3_CV_RATIO,
  field.PAYBACK_PERIOD_PREDICTION,
  field.PAYBACK_PERIOD_DIFFERENCE,
  field.LTV_AMOUNT,
  field.LTV_AMOUNT_TOTAL,
  field.LTV_PROFIT,
  field.LTV_PROFIT_TOTAL,
  field.MARGINAL_PROFIT,
  field.ROI,
];

const MATCH_TYPE_ID = {
  CONTAIN: '0',
  FIX_CONTAIN: '1',
  NOT_CONTAIN: '2',
  FRONT: '3',
};

const CROSS_DEVICE_METRICS = [
  field.CV_TOTAL,
  field.CNT_FIRST,
  field.RAMOUNT,
  field.RCV,
  field.CROSSDEVICE_DIFF_COMPARISON,
];

export const MATCH_TYPE = {
  [MATCH_TYPE_ID.CONTAIN]: 'を含む',
  [MATCH_TYPE_ID.FIX_CONTAIN]: 'に完全一致',
  [MATCH_TYPE_ID.NOT_CONTAIN]: 'を含まない',
  [MATCH_TYPE_ID.FRONT]: 'から始まる',
};

export const MATCH_TYPE_FIELDS = [
  field.PAGE_URLS,
  field.ROUTE_URLS,
  field.CV_URLS,
];

export const ARRTOSTR_FIELDS = [field.PAGE_IDS];

export const TEXT_NON_SETUP = '未設定';

export const BOOLEAN_FIELDS = [field.OWNED_MEDIA_FLAG];
export const TRUE_FLAG = 't';
export const FALSE_FLAG = 'f';

// Google params
export const GOOGLE_OAUTH2_AUTH_URL =
  'https://accounts.google.com/o/oauth2/auth';
export const GOOGLE_SCOPE_DRIVE_FILE =
  'https://www.googleapis.com/auth/drive.file';
export const GOOGLE_DRIVE_ABOUT_V3 =
  'https://www.googleapis.com/drive/v3/about';
export const GOOGLE_REDIRECT_URI = '/google-auth.html';
export const GOOGLE_ACCESS_TYPE_OFFLINE = 'offline';
export const GOOGLE_RESPONSE_TYPE_CODE = 'code';
export const GOOGLE_APPROVAL_PROMPT_FORCE = 'force';

// Google Ads API
export const GOOGLE_ADWORDS_SCOPE = 'https://www.googleapis.com/auth/adwords';
export const GOOGLE_ADS_REDIRECT_URI = '/google-ads-auth.html';

// Google Search Console API
export const SEARCH_CONSOLE_WEB_MASTER =
  'https://searchconsole.googleapis.com/webmasters/v3/sites';
export const GOOOLE_SC_SCOPE =
  'https://www.googleapis.com/auth/webmasters.readonly';
export const GOOGLE_SC_REDIRECT_URI = '/google-sc-auth.html';
// Yahoo params
export const YAHOO_OAUTH2_AUTH_URL =
  'https://biz-oauth.yahoo.co.jp/oauth/v1/authorize';
// X Conversion API
export const X_CONVERSION_API_OAUTH1_AUTH_URL =
  'https://api.x.com/oauth/authorize';
export const X_CONVERSION_API_OAUTH1_AUTH_URL_PARAM = '?oauth_token=';
// Microsoft Conversion Import
export const MICROSOFT_OAUTH2_AUTH_URL =
  'https://login.microsoftonline.com/common/oauth2/v2.0/authorize';
export const YAHOO_ADS_RESPONSE_TYPE_CODE = 'code';
export const MICROSOFT_RESPONSE_TYPE_CODE = 'code';
// Number results of Base Account Service
export const BASE_ACCOUNT_NUMBER_RESULTS = 200;
// Number results of Account Service
export const ACCOUNT_NUMBER_RESULTS = 500;
export const DEFAULT_START_INDEX = 1;
export const YAHOO_QPS_INTERVAL = 600;
export const X_CONVERSION_API_QPS_INTERVAL = 600;
export const MICROSOFT_QPS_INTERVAL = 600;

// Yahoo! JAPAN Ads API
export const YAHOO_ADS_SCOPE = 'yahooads';
export const YAHOO_ADS_REDIRECT_URI = '/yahoo-ads-auth.html';

// X Conversion API
export const X_CONVERSION_API_REDIRECT_URI = '/x-ads-auth.html';

// Microsoft Conversion Import
export const MICROSOFT_SCOPE =
  'offline_access https://ads.microsoft.com/msads.manage';
export const MICROSOFT_REDIRECT_URI = '/microsoft-ads-auth.html';

// Error code
export const GOOGLE_INVALID_CREDENTIALS = 1401;
export const GOOGLE_INVALID_AUTH = 'INVALID_AUTH';
export const GOOGLE_AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_CUSTOMER_ID_EMPTY = 'LOGIN_CUSTOMER_ID_EMPTY';
export const YAHOO_AUTH_ERROR = 'YAHOO_AUTH_ERROR';
export const YAHOO_INVALID_AUTH = 'INVALID_AUTH';
export const YAHOO_BASE_ACCOUNT_ID_NOT_FOUND =
  'YAHOO_BASE_ACCOUNT_ID_NOT_FOUND';
export const YAHOO_AD_ACCOUNT_EMPTY = 'YAHOO_AD_ACCOUNT_EMPTY';
export const X_CONVERSION_API_AUTH_ERROR = 'AUTH_ERROR';
// export const X_CONVERSION_API_INVALID_AUTH = 'INVALID_AUTH';
export const X_CONVERSION_API_FETCH_URL_ERROR =
  'X_CONVERSION_API_FETCH_URL_ERROR';
export const X_CONVERSION_API_FETCH_TOKEN_ERROR =
  'X_CONVERSION_API_FETCH_TOKEN_ERROR';
export const X_CONVERSION_API_AUTH_CHECK_ERROR = 'AUTH_CHECK_ERROR';
export const MICROSOFT_AUTH_ERROR = 'AUTH_ERROR';
export const MICROSOFT_INVALID_AUTH = 'INVALID_AUTH';
export const MICROSOFT_FETCH_SECRETS_MANAGER_ERROR =
  'MICROSOFT_FETCH_SECRETS_MANAGER_ERROR';
export const MICROSOFT_FETCH_TOKEN_ERROR = 'MICROSOFT_FETCH_TOKEN_ERROR';
export const MICROSOFT_AUTH_CHECK_ERROR = 'AUTH_CHECK_ERROR';

// Error message
export const GOOGLE_AUTH_ERRORS = {
  [GOOGLE_INVALID_AUTH]:
    'Googleアカウントの認証に失敗しました。再度認証を行ってください。',
  [GOOGLE_AUTH_ERROR]:
    'Googleアカウントの認証に失敗しました。再度認証を行ってください。',
  [GOOGLE_INVALID_CREDENTIALS]:
    'レポート作成中に、Googleアカウントの認証が無効になりました。再度認証を行ってください。',
};

export const GOOGLE_SEARCH_CONSOLE_ERROR_CODE = {
  unauthorized: 401,
  forbidden: 403,
  notFound: 404,
  rateLimitExceeded: 429,
  internalError: 500,
  requestTooLarge: 413,
};

// Error message
export const GOOGLE_AUTH_SEARCH_CONSOLE_ERRORS = {
  [GOOGLE_SEARCH_CONSOLE_ERROR_CODE.unauthorized]:
    'Googleアカウントの認証に失敗しました。再度認証を行ってください。',
};

// Google Ads Auth Error Message
export const GOOGLE_ADS_AUTH_ERRORS = {
  [GOOGLE_INVALID_AUTH]:
    'Googleアカウントの認証に失敗しました。再度認証を行ってください。',
  [GOOGLE_AUTH_ERROR]:
    'Googleアカウントの認証に失敗しました。再度認証を行ってください。',
  [LOGIN_CUSTOMER_ID_EMPTY]: 'データがありません',
};

// Yahoo Ads Auth Error Message
export const YAHOO_ADS_AUTH_ERRORS = {
  [YAHOO_INVALID_AUTH]:
    'Yahooアカウントの認証に失敗しました。再度認証を行ってください。',
  [YAHOO_BASE_ACCOUNT_ID_NOT_FOUND]: 'x-z-base-account-id が見つかりません',
  [YAHOO_AD_ACCOUNT_EMPTY]: 'データがありません',
  [YAHOO_AUTH_ERROR]:
    'Yahooアカウントの認証に失敗しました。再度認証を行ってください。',
};

// X Conversion API Auth Error Message
// With this <br/>, line breaks will not occur, so reconvert with MediaInfoSetting.jsx
export const X_CONVERSION_API_AUTH_ERRORS = {
  // [X_CONVERSION_API_INVALID_AUTH]: 'Xアカウントの認証に失敗しました。再度認証を行ってください。',
  [X_CONVERSION_API_AUTH_ERROR]:
    '認証に失敗しました。再度認証を行ってください。<br/>それでも認証できない場合は、Xアカウントの権限や設定をご確認ください。',
  [X_CONVERSION_API_FETCH_URL_ERROR]:
    '認証に失敗しました。再度認証を行ってください。<br/>それでも認証できない場合は、Xアカウントの権限や設定をご確認ください。',
  [X_CONVERSION_API_FETCH_TOKEN_ERROR]:
    '認証に失敗しました。再度認証を行ってください。<br/>それでも認証できない場合は、Xアカウントの権限や設定をご確認ください。',
  [X_CONVERSION_API_AUTH_CHECK_ERROR]:
    '認証に失敗しました。再度認証を行ってください。<br/>それでも認証できない場合は、Xアカウントの権限や設定をご確認ください。',
};

// Microsoft Conversion Import Auth Error Message
// With this <br/>, line breaks will not occur, so reconvert with MediaInfoSetting.jsx
export const MICROSOFT_AUTH_ERRORS = {
  [MICROSOFT_INVALID_AUTH]:
    '認証に失敗しました。再度認証を行ってください。<br/>それでも認証できない場合は、Microsoftアカウントの権限や設定をご確認ください。',
  [MICROSOFT_AUTH_ERROR]:
    '認証に失敗しました。再度認証を行ってください。<br/>それでも認証できない場合は、Microsoftアカウントの権限や設定をご確認ください。',
  [MICROSOFT_FETCH_TOKEN_ERROR]:
    '認証に失敗しました。再度認証を行ってください。<br/>それでも認証できない場合は、Microsoftアカウントの権限や設定をご確認ください。',
  [MICROSOFT_AUTH_CHECK_ERROR]:
    '認証に失敗しました。再度認証を行ってください。<br/>それでも認証できない場合は、Microsoftアカウントの権限や設定をご確認ください。',
};

// Define the character string of "button to switch display / non-display" on the list display screen.
// e.g. { screenId: { fieldName: [msg when hidden, msg when display] } }
const HIDE_SWITCH_BTN_MESSAGES = {
  [MEDIA_SYNC_MANAGEMENT]: {
    [field.ADVERTISING_PARAMETER]: ['パラメータを表示', '非表示'],
  },
};

export {
  Metric,
  REPORT_TYPE,
  CHANNEL_TYPE,
  COMMA_SEPARATOR,
  ONE_BYTE_COMMA_SEPERATOR,
  CvFlowFilterMetrics,
  CvFlowIndirectMetrics,
  DATE_RANGE_TYPE,
  CURRENT_PERIOD_AVAILABLE_PRESETS,
  COMPARED_PERIOD_AVAILABLE_PRESETS,
  CALENDAR_MONTH_FORMAT,
  CALENDAR_DAY_FORMAT,
  CALENDAR_INPUT_ALLOWED_FORMAT,
  CURRENCY_SYMBOL,
  CURRENCY_SYMBOL_2,
  PERCENTAGE_SYMBOL,
  TIME_SECONDS_SYMBOL,
  FIELD,
  FIELD_API_RESPONSE,
  FIELD_PREFIX,
  FIELD_PREFIX_CONTACT,
  PERCENTAGE_FIELDS,
  DECIMAL_FIELDS,
  PERIOD_FIELDS,
  PERIOD_MONTHS_FIELDS,
  URL_FIELDS,
  DATE_FIELDS,
  DATE_TIME_FIELDS,
  DATE_RAGNGE_FIELDS,
  CHANNNEL_FIELDS,
  TERMINAL_FIELDS,
  MEASUREMENT_FIELDS,
  AD_TYPE_FIELDS,
  CHART_COLORS,
  COMPARE_PERIOD_X_LABEL_DATE_FORMAT,
  COMPARE_PERIOD_X_LABEL_MONTH_FORMAT,
  TABLE_ROWS_PER_PAGE,
  CURRENCY_FIELDS,
  CV_FIELD_PREFIX,
  NEW_CV_FIELD_PREFIX,
  CVR_FIELD_PREFIX,
  NEW_CVR_FIELD_PREFIX,
  NUMBER_FIELDS,
  API_DATE_FORMAT,
  API_MONTH_FORMAT,
  EXPORT_TYPE,
  BOOKMARK_FUNC_NAME,
  FREQUENCY_FIELDS,
  FREQUENCY_MAXIMUM,
  JA_JP,
  JPY,
  CV_METRICS,
  NEW_CV_METRICS,
  NEW_CPA_METRICS,
  PAYBACK_PERIOD_METRICS,
  CHANNEL_TYPE_ID,
  CHANNEL_SUMMARY_KEY,
  CHANNEL_TYPE_LABEL,
  UNREGISTERED_IDS,
  UNREGISTERED_IDS_METRICS,
  UNREGISTERED_ID_FILTER_KEY,
  UNREGISTERED_DISPLAY_VALUE,
  UNSYNCED_DISPLAY_VALUE,
  PAGE_DELETED_DISPLAY_VALUE,
  PAGE_ID_OVER_LIMITED,
  NO_PERMISSION_DISPLAY_VALUE,
  COST_LINKING_EMPTY_IDS,
  COST_LINKING_EMPTY_DISPLAY_VALUE,
  CHART_EXPORT_IMAGE_ID,
  CHART_EXPORT_EXCLUDED_CLASSNAME,
  HIGHCHARTS_EXPORT_EXCLUDED_CLASSLIST,
  JP_DAY_OF_WEEK,
  FILTER_CONTACT_HISTORY_DIRECT,
  FILTER_CONTACT_HISTORY_INDIRECT2,
  FILTER_CONTACT_HISTORY_INDIRECT3,
  FILTER_CONTACT_HISTORY_INDIRECT4,
  FILTER_CONTACT_HISTORY_INDIRECT5,
  FILTER_CONTACT_HISTORY_INDIRECT6,
  FILTER_CONTACT_HISTORY_INDIRECT7,
  FILTER_CONTACT_HISTORY_INDIRECT8,
  FILTER_CONTACT_HISTORY_INDIRECT9,
  FILTER_CONTACT_HISTORY_INDIRECT10,
  FILTER_CONTACT_HISTORY_FIRST,
  FILTER_CONTACT_HISTORY,
  FILTER_UNREGISTERED_ITEM,
  subHeaderDefinitions,
  DEFAULT_SELECTED_CATEGORIES,
  MAX_CATEGORIES_SELECTION,
  MAX_CUSTOM_VIEW_COUNT,
  MAX_ITEMS_DISPLAY_IN_COLUMN,
  PERIOD_COMPARABLE_SCREEN_IDS,
  COLUMN_NOWRAP,
  COLUMN_NOWRAP_WITH_TOOLTIP,
  COLUMN_TEXT_ALIGN_RIGHT,
  CUSTOM_DISPLAY_FIELDS,
  FILTER_CONTACT_HISTORY_LENGTH,
  MASTER_DATA_FILTER_LIMIT,
  CSV_MAX_DOWNLOADABLE_RECORDS,
  CSV_DOWNLOAD_TYPE,
  UNLIMITED_VALUE,
  API_UNLIMITED_VALUE,
  MASTER_DATA_TYPE,
  DASHBOARD_ANALYSIS_TYPE,
  MAINTENANCE_TYPE,
  AD_MANAGEMENT_MODE,
  MEASUREMENT_TYPE_REDIRECT,
  MEASUREMENT_TYPE_DIRECT,
  CSV_MAX_SIZE,
  SCREEN_DATE_TIME_FORMAT,
  UPDATE_TIME_FORMAT,
  AD_MANAGEMENT_STATUS_ACTION,
  CONTENT_TYPE,
  AGENCY_LIMIT,
  AGENCY_FILTER_LIMIT,
  HYPHEN_FIELDS,
  FILTER_CONTACT_HISTORY_DEFAULT,
  PRIORITY_AXIS_MEDIA_SYNC,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_BOTH,
  METRICS_VIEW_HIDE,
  METRICS_VIEW_SIMPLE,
  SYNC_SOURCE_ITEMS,
  EBIS_SOURCE_ITEMS,
  MEDIA_SIDE_ITEMS,
  MEDIA_SIDE_ITEMS_AD_MANAGEMENT,
  EBIS_ITEMS,
  MEDIA_ACCOUNT_FILTER_UNREGISTERED_ITEM,
  CAMPAIGN_GROUP_FILTER_UNREGISTERED_ITEM,
  DEFAULT_MAX_LINE_CSV,
  MEDIA_ACCOUNT_UNREGISTERED_ID_FILTER_KEY,
  CAMPAIGN_GROUP_UNREGISTERED_ID_FILTER_KEY,
  UNREGISTERED_UNSYNCED_DISPLAY_VALUE,
  HIDE_SWITCH_BTN_MESSAGES,
  MAX_SELECTED_MEDIA_SETTING_MAIL,
  CROSS_DEVICE_METRICS,
};
