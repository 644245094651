import helper from 'store/helper';
import loggerConstant from 'store/logger/constant';
import types from 'store/capi-setting/types';

const getData = () => helper.createAction(types.GET_DATA);

const setData = (data) => helper.createAction(types.SET_DATA, { data });

const setStatus = (status, key) =>
  helper.createAction(types.SET_STATUS, { status, key });

const setErrors = (errors, type, scope = loggerConstant.SCOPE_DONT_SHOW) =>
  helper.createAction(types.SET_ERRORS, { errors, type }, scope);

const updateSort = (field, direction) =>
  helper.createAction(types.UPDATE_SORT, { field, direction });

const changeStatus = (id, status) =>
  helper.createAction(types.CHANGE_STATUS, { id, status });

const deleteCapis = (ids, callback) =>
  helper.createAction(types.DELETE, { ids, callback });

const getHistory = () => helper.createAction(types.GET_HISTORY);

const setHistory = (data) => helper.createAction(types.SET_HISTORY, { data });

const setStatusSetting = (status) =>
  helper.createAction(types.SET_STATUS_SETTING, { status });
const setErrorAuthentication = (
  errors,
  scope = loggerConstant.SCOPE_DONT_SHOW
) => helper.createAction(types.SET_ERROR_DETAIL, { errors }, scope);

const fbAuthenticationCheck = (data, changeStep, callback = () => {}) =>
  helper.createAction(types.AUTHENTICATION_CHECK, {
    data,
    changeStep,
    callback,
  });

const ggAuthenticationCheck = (data, changeStep, callback = () => {}) =>
  helper.createAction(types.GOOGLE_AUTHENTICATION_CHECK, {
    data,
    changeStep,
    callback,
  });

const lnAuthenticationCheck = (data, changeStep, callback = () => {}) =>
  helper.createAction(types.LINE_AUTHENTICATION_CHECK, {
    data,
    changeStep,
    callback,
  });

const yahooAuthenticationCheck = (data, changeStep, callback = () => {}) =>
  helper.createAction(types.YAHOO_AUTHENTICATION_CHECK, {
    data,
    changeStep,
    callback,
  });

const ttAuthenticationCheck = (data, changeStep, callback = () => {}) =>
  helper.createAction(types.TIKTOK_AUTHENTICATION_CHECK, {
    data,
    changeStep,
    callback,
  });

const xcAuthenticationCheck = (data, changeStep, callback = () => {}) =>
  helper.createAction(types.X_CONVERSION_API_AUTHENTICATION_CHECK, {
    data,
    changeStep,
    callback,
  });

const mfAuthenticationCheck = (data, changeStep, callback = () => {}) =>
  helper.createAction(types.MICROSOFT_AUTHENTICATION_CHECK, {
    data,
    changeStep,
    callback,
  });
      
const setErrorSetting = (errors, scope) =>
  helper.createAction(types.SET_ERROR_SETTING, { errors }, scope);

const setDataSetting = (data) =>
  helper.createAction(types.SET_DATA_SETTING, { data });

const create = (data, callback = () => {}) =>
  helper.createAction(types.CREATE, { data, callback });
const getDetail = (id, callback = () => {}) =>
  helper.createAction(types.GET_DETAIL, { id, callback });
const update = (id, data, callback = () => {}) =>
  helper.createAction(types.UPDATE, { id, data, callback });

const getMasterData = () => helper.createAction(types.GET_MASTERDATA);
const setMasterData = (data) =>
  helper.createAction(types.SET_MASTERDATA, { data });

const downloadCsvPostbackHistory = () =>
  helper.createAction(types.DOWNLOAD_CSV_POSTBACK_HISTORY);

const getYahooAccounts = (
  data,
  handleSuccess = () => {},
  handleFailed = () => {}
) =>
  helper.createAction(types.GET_YAHOO_ACCOUNTS, {
    data,
    handleSuccess,
    handleFailed,
  });

const getGoogleAccounts = (
  data,
  handleSuccess = () => {},
  handleFailed = () => {}
) =>
  helper.createAction(types.GET_GOOGLE_ACCOUNTS, {
    data,
    handleSuccess,
    handleFailed,
  });

const capiSettingActions = {
  getData,
  setData,
  setStatus,
  setErrors,
  updateSort,
  changeStatus,
  deleteCapis,
  getHistory,
  setHistory,
  setStatusSetting,
  setErrorAuthentication,
  fbAuthenticationCheck,
  ggAuthenticationCheck,
  lnAuthenticationCheck,
  yahooAuthenticationCheck,
  ttAuthenticationCheck,
  xcAuthenticationCheck,
  mfAuthenticationCheck,
  setErrorSetting,
  setDataSetting,
  create,
  getDetail,
  update,
  getMasterData,
  setMasterData,
  downloadCsvPostbackHistory,
  getYahooAccounts,
  getGoogleAccounts,
};

export default capiSettingActions;
