import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import ControlBar from 'views/molecules/control-bar';
import GridTableTemplate from 'views/templates/GridTableTemplate';
import {
  HEADER_LIST_REPORT,
  MSG_NO_PERMISSION_SETTING,
  VIEW_HISTORY,
} from 'domain/data-export/consts';
import { formatDataList } from 'services/data-export/dataExportService';
import { getErrorMessageByCode } from 'services/utils';
import * as messageError from 'services/validations/messageErrorByCode';
import useModalController from 'services/custom-hooks/useModalController';
import { DATA_EXPORT } from 'services/routes/constants';
import { getAgentFlag } from 'store/auth/selectors';
import dataExportActions from 'store/data-export/actions';
import dataExportSelectors from 'store/data-export/selectors';
import ConfirmModal from 'views/molecules/modal/ConfirmModal';
import DeleteConfirmModal from 'views/molecules/modal/DeleteConfirmModal';
import useCheckPermissionSettingReport from 'services/custom-hooks/useCheckPermissionSettingReport';

import './data-export.scss';
import useCheckCrossDevicePaidContract from 'services/custom-hooks/useCheckCrossDevicePaidContract';
import usageStatusActions from 'store/usage-status/actions';
import ViewHistory from './components/ViewHistory';

const ModalId = {
  DELETE: 'delete',
  ERROR: 'error',
  HISTORY: 'history',
};

function ListReportContainer() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [
    { listData, settings, isLoading: isLoadingList, statusSystemSync },
    { isLoadingListHistory, listHistory },
  ] = [
    useSelector(dataExportSelectors.getStatesForList, shallowEqual),
    useSelector(dataExportSelectors.getStatesForListHistory, shallowEqual),
  ];
  const isUserAgent = useSelector(getAgentFlag, shallowEqual);

  const {
    isHavePermissionAdrepo,
    isHavePermissionDataExport,
    isAllowedSettingReport,
  } = useCheckPermissionSettingReport();

  const { openModal, closeModals, isOpen } = useModalController({
    ids: [ModalId.HISTORY, ModalId.DELETE, ModalId.ERROR],
  });

  const [selectedRows, setSelectedRows] = useState([]);
  const [listReportsDelete, setListReportsDelete] = useState([]);
  const [isLoadingDelete, setLoadingDelete] = useState(false);
  const [error, serError] = useState({
    title: '',
    message: '',
    callback: () => {},
  });

  const [infoDelete, setInfoDelete] = useState({
    title: '',
    content: '',
    force: '',
    isShowButtonSubmit: true,
    hideText: '',
    dialogClassName: '',
    subItemsIntegrateCloudNotDelete: [],
  });

  const {
    maxItems: { maxItemDataExport, maxItemAdrepo, maxItemRegularReport },
    pagination: {
      totalItems: {
        countDataExport,
        countAllDataExport,
        countRegularReport,
        countAllRegularReport,
        countAdrepo,
      },
    },
  } = settings;

  const getList = useCallback(() => {
    dispatch(dataExportActions.getList());
  }, [dispatch]);

  const handleShowErrorModal = useCallback(
    (title, message, callback = () => {}) => {
      serError({ title, message, callback });
      openModal(ModalId.ERROR);
    },
    [openModal]
  );

  const handleResponseViewHistory = useCallback(
    ({ errors }) => {
      if (
        errors?.length > 0 &&
        ['REPORT_ID_EXISTS_CHECK', 'ALLOW_USER_MODIFY_REPORT_CHECK'].includes(
          errors[0].code
        )
      ) {
        closeModals();
        const message = getErrorMessageByCode(errors[0], messageError);
        handleShowErrorModal('データ更新に失敗しました', message, getList);
      }
    },
    [closeModals, getList, handleShowErrorModal]
  );

  const onClickViewHistory = useCallback(
    (reportId) => {
      openModal(ModalId.HISTORY);
      dispatch(
        dataExportActions.getListHistory(reportId, handleResponseViewHistory)
      );
    },
    [dispatch, handleResponseViewHistory, openModal]
  );

  const handleResponseDownload = useCallback(
    ({ errors }) => {
      if (errors?.length > 0) {
        const message = getErrorMessageByCode(errors[0], messageError);
        const isReload = [
          'REPORT_FILE_NAME_CHECK',
          'ALLOW_USER_MODIFY_REPORT_CHECK',
          'REPORT_ID_EXISTS_CHECK',
        ].includes(errors[0].code);

        // update when JP confirm text message
        handleShowErrorModal(
          'データ更新に失敗しました',
          message,
          isReload ? getList : undefined
        );
      }
    },
    [handleShowErrorModal, getList]
  );

  const onClickDownload = useCallback(
    (reportId, spreadsheetLink) => {
      if (spreadsheetLink) {
        window.open(spreadsheetLink, '_blank');
      } else {
        dispatch(
          dataExportActions.downloadCsv(reportId, handleResponseDownload)
        );
      }
    },
    [dispatch, handleResponseDownload]
  );

  const headerTable = useMemo(() => {
    return HEADER_LIST_REPORT.reduce((acc, item) => {
      if (isUserAgent && ['rowId', VIEW_HISTORY].includes(item.field)) {
        return acc;
      }

      if (item.field === VIEW_HISTORY) {
        return [...acc, { ...item, onClick: onClickViewHistory }];
      }

      return [...acc, item];
    }, []);
  }, [isUserAgent, onClickViewHistory]);

  const { paidContract } = useCheckCrossDevicePaidContract();

  const rowsDataList = useMemo(() => {
    return formatDataList({
      lists: listData,
      onClickDownload,
      isAllowedSettingReport,
      isHavePermissionDataExport,
      paidContract,
    });
  }, [
    listData,
    onClickDownload,
    isAllowedSettingReport,
    isHavePermissionDataExport,
    paidContract,
  ]);

  useEffect(() => {
    getList();
  }, [getList]);

  const handleSelectRow = (ids) => {
    setSelectedRows(ids);
  };

  const handleEdit = (reportId) => {
    // will complete when edit
    history.push(`/data-export/setting/${reportId}`);
  };

  const handleConfirmModal = () => {
    closeModals();
    error.callback();
  };

  const handleShowConfirmDelete = (
    isShow,
    isDeleteLinkCloud = false,
    reportLinkClouds = []
  ) => {
    let itemsDelete = [];
    const itemsIntegrateCloudNotDelete = [];
    let titleDelete = 'レポートの削除を実行してもよろしいですか？';
    let contentDelete =
      '削除すると、設定内容の閲覧とデータ出力ができなくなります。';
    let forceDelete = '設定を削除しても問題ありません';
    let isShowButtonSubmit = true;
    let hideText = 'キャンセル';
    let dialogClassName = '';
    const listItemSeleted = listData.filter((row) =>
      selectedRows.includes(row.id)
    );

    listItemSeleted.forEach((item, index) => {
      const reportId = _.isNumber(item.id) ? item.id.toString() : item.id;
      if (isDeleteLinkCloud && reportLinkClouds.includes(reportId)) {
        itemsIntegrateCloudNotDelete.push({
          order: index,
          value: item.name,
        });
      } else {
        itemsDelete.push({
          order: index,
          value: item.name,
        });
      }
    });

    if (itemsIntegrateCloudNotDelete.length > 0) {
      titleDelete = 'レポートの削除が実行できません';
      contentDelete = (
        <>
          以下のレポートは
          <span className="font-weight-bold">
            クラウドストレージ×ADEBiS連携に利用されているため、削除できません。
          </span>
          <br />
          連携設定を解除してから、再度削除を行ってください。
        </>
      );
      forceDelete = '';
      isShowButtonSubmit = false;
      hideText = '閉じる';
      dialogClassName = 'modal--delete-confirm--w650';
      itemsDelete = [];
    }

    setInfoDelete({
      title: titleDelete,
      content: contentDelete,
      force: forceDelete,
      isShowButtonSubmit,
      hideText,
      dialogClassName,
      subItemsIntegrateCloudNotDelete: itemsIntegrateCloudNotDelete,
    });

    setListReportsDelete(itemsDelete);
    openModal(ModalId.DELETE);
  };

  const handleResponseDelete = useCallback(
    ({ isError = false, errors }) => {
      closeModals();
      setLoadingDelete(false);
      if (!isError) {
        setSelectedRows([]);
        getList();
      } else if (errors?.length > 0) {
        const message = getErrorMessageByCode(errors[0], messageError);
        const isReload = [
          'REPORT_RUNNING_CHECK',
          'ALLOW_USER_MODIFY_REPORT_CHECK',
          'REPORT_ID_EXISTS_CHECK',
        ].includes(errors[0].code);

        if (errors[0].code === 'REPORT_IS_LINKING_CLOUD_CHECK') {
          const reportLinkClouds = errors[0]?.metadata?.value || [];
          handleShowConfirmDelete(true, true, reportLinkClouds);
          getList();
        } else {
          handleShowErrorModal(
            'データ削除に失敗しました',
            message,
            isReload ? getList : undefined
          );
        }
      }
    },
    [closeModals, handleShowErrorModal, getList]
  );

  const handleSubmitDelete = useCallback(() => {
    setLoadingDelete(true);
    dispatch(dataExportActions.deleteItems(selectedRows, handleResponseDelete));
  }, [dispatch, handleResponseDelete, selectedRows]);

  const handleShowSettingModal = () => {
    history.push('/data-export/setting');
  };

  // will complete paginations when impletment check permission
  // caculate total report items by user
  const totalReportItems = countDataExport + countAdrepo + countRegularReport;

  // caculate max report items by user
  const maxRegularReport =
    maxItemRegularReport - countAllRegularReport + countRegularReport;
  const maxDataExport =
    maxItemDataExport - countAllDataExport + countDataExport;
  const maxAdrepo = maxItemAdrepo - countAdrepo + countAdrepo;
  const maxReportItems = maxRegularReport + maxDataExport + maxAdrepo;

  useEffect(() => {
    dispatch(usageStatusActions.getDataUsageStatus());
  }, [dispatch]);

  return (
    <div className="data-export h-100">
      <GridTableTemplate
        isTableCenter
        isLoading={isLoadingList}
        pageId={DATA_EXPORT}
        variant={DATA_EXPORT}
        header={headerTable}
        rows={rowsDataList}
        onSelectRow={handleSelectRow}
        onEditRow={handleEdit}
        tableControl={
          <>
            <ControlBar
              page={DATA_EXPORT}
              currentItems={totalReportItems}
              maximumItems={maxReportItems}
              checkedItemsNum={selectedRows.length}
              handleShowSettingModal={
                isUserAgent ? false : handleShowSettingModal
              }
              setShowConfirmModal={handleShowConfirmDelete}
              isShowDeleteButton={!isUserAgent}
              disabledRegister={!isAllowedSettingReport}
              buttonAddTooltipText={
                isAllowedSettingReport ? '' : MSG_NO_PERMISSION_SETTING
              }
            />
            {!isLoadingList && (
              <div className="ml-auto mr-30">
                {(() => {
                  let maxRegister = `登録上限数${maxReportItems}件`;
                  let regularReportRegistered = `${countRegularReport}/${maxRegularReport}`;
                  let dataExportRegistered = `${countDataExport}/${maxDataExport}`;
                  let adrepoRegistered = `${countAdrepo}/${maxAdrepo}`;
                  if (isUserAgent) {
                    maxRegister = `計${totalReportItems}件`;
                    regularReportRegistered = countRegularReport;
                    dataExportRegistered = countDataExport;
                    adrepoRegistered = countAdrepo;
                  }

                  if (statusSystemSync) {
                    if (isHavePermissionDataExport && isHavePermissionAdrepo) {
                      return `${maxRegister}（有償版レポート${dataExportRegistered}件・無償版レポート${regularReportRegistered}件・アドレポ連携${adrepoRegistered}件）`;
                    }
                    if (isHavePermissionDataExport && !isHavePermissionAdrepo) {
                      return `${maxRegister}（有償版レポート${dataExportRegistered}件・無償版レポート${regularReportRegistered}件）`;
                    }
                    if (!isHavePermissionDataExport && isHavePermissionAdrepo) {
                      return `${maxRegister}（通常レポート${regularReportRegistered}件・アドレポ連携${adrepoRegistered}件）`;
                    }
                  }
                  return isUserAgent
                    ? maxRegister
                    : `計${totalReportItems}件/${maxRegister}`;
                })()}
              </div>
            )}
          </>
        }
      />
      {isOpen(ModalId.HISTORY) && (
        <ViewHistory
          show={isOpen(ModalId.HISTORY)}
          handleCloseModal={closeModals}
          isLoading={isLoadingListHistory}
          data={listHistory}
        />
      )}
      {isOpen(ModalId.DELETE) && (
        <DeleteConfirmModal
          isShow
          isLoading={isLoadingDelete}
          title={infoDelete.title}
          content={infoDelete.content}
          items={listReportsDelete}
          forceText={infoDelete.force}
          onSubmit={handleSubmitDelete}
          onHide={closeModals}
          hideText={infoDelete.hideText}
          isShowButtonSubmit={infoDelete.isShowButtonSubmit}
          dialogClassName={infoDelete.dialogClassName}
          subItemsIntegrateCloudNotDelete={
            infoDelete.subItemsIntegrateCloudNotDelete
          }
        />
      )}
      {isOpen(ModalId.ERROR) && (
        <ConfirmModal
          isShow
          title={error.title}
          content={error.message}
          confirmText="OK"
          cancelBtn={false}
          onSubmit={handleConfirmModal}
        />
      )}
    </div>
  );
}

export default ListReportContainer;
