import { has, isEmpty } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import usageStatusSelectors from 'store/usage-status/selectors';
import { USAGE_STATUS_FIELDS } from 'domain/usage-status/consts';
import { reflectionTimeSelectors } from 'store/reflectiontime';
import { useMemo } from 'react';
import { CROSS_DEVICE } from 'services/common/reflectiontime/constants';

const { OPTIONS } = USAGE_STATUS_FIELDS;
const CROSS_PAID = 2;
const NO_CROSS_PAID = 0;

const useCheckCrossDevicePaidContract = () => {
  const { usageStatusData } = useSelector(
    usageStatusSelectors.getStatesForUsageStatus,
    shallowEqual
  );

  const reflectionData = useSelector(
    reflectionTimeSelectors.getReflectionTimeData,
    shallowEqual
  );

  const isCrossDevicePaid = useMemo(() => {
    if (isEmpty(reflectionData)) return false;

    if (!isEmpty(reflectionData[CROSS_DEVICE])) {
      const crossDeviceData = reflectionData[CROSS_DEVICE][0] || {};
      return crossDeviceData.paid_contract === CROSS_PAID;
    }

    return false;
  }, [reflectionData]);

  const { [OPTIONS]: options = {} } = usageStatusData;
  const paidContract = has(options, 'CROSS_PAID-1')
    ? CROSS_PAID
    : NO_CROSS_PAID;

  return {
    paidContract,
    isCrossDevicePaid,
  };
};

export default useCheckCrossDevicePaidContract;
